import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "css/allCopy.css";
import "css/base.css";
import "css/contentsCopy.css";
import "css/font.css";
import "css/swiper.css";

const DefaultLayout = ({ nav, content, subMenu }) => {
  return (
    <>
      <Header />
      {subMenu}
      <div id="contents">
        {nav}
        {content}
      </div>
      <Footer />
    </>
  );
};

export default DefaultLayout;
