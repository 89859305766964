import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const Nav1 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    return (
    <div id="lnb">
        <div className="lnb_w">	
            <ul >
                <li><a className={location.pathname === "/about" ? "on" : ""} onClick={()=>{navigate('/about')}} >회사개요</a></li>
                <li><a className={location.pathname === "/ceo" ? "on" : ""} onClick={()=>{navigate('/ceo')}} >CEO 인사말</a></li>    
                <li><a className={location.pathname === "/philosophy" ? "on" : ""} onClick={()=>{navigate('/philosophy')}} >기업이념</a></li>            
                <li><a className={location.pathname === "/history" ? "on" : ""} onClick={()=>{navigate('/history')}} >회사연혁</a></li>
                <li><a className={location.pathname === "/organization" ? "on" : ""} onClick={()=>{navigate('/organization')}} >회사조직</a></li>
                <li><a className={location.pathname === "/location" ? "on" : ""} onClick={()=>{navigate('/location')}} >오시는 길</a></li>
            </ul>
        </div>
    </div>
    );
};

export default Nav1;