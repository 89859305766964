import React, { useEffect, useState } from "react";
import ai from "../../img/company/cp_ico01.png";
import handShake from "../../img/company/cp_ico02.png";
import people from "../../img/company/cp_ico03.png";
import { useDisHomePageMgtList } from "hooks/services/companyInfo";

const About = () => {
  //=============================================================================================================================================================================
  // useState
  //=============================================================================================================================================================================
  const [dataList, setDataList] = useState({});

  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 회사연혁 조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disHomePageMgtList } = useDisHomePageMgtList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setDataList(data.result[0]);
      } else {
      }
    },
  });

  useEffect(() => {
    disHomePageMgtList([null, {}]);
  }, []);

  return (
    <div id="contents_in" className="about">
      <div className="tit_area">
        <h3 className="title">회사개요</h3>
      </div>

      {/* 비전 */}
      <div className="content01">
        <div className="container">
          <div className="title">
            창의적인 방식, 선도적인 새로운 기술,
            <br />
            <strong>적극적인 소통과 협력</strong>을 바탕으로 <strong>지속적인 성장</strong>을 합니다.
          </div>
          <div className="cbox_w">
            <ul>
              <li>
                <div className="cb01">
                  <div className="tg01">
                    <figure>
                      <img src={ai} alt="" />
                    </figure>
                    <div className="t01">
                      <b>고객에게 감동을 전하는 ICT 전문기업</b>
                    </div>
                    <div className="t02">
                      다이나시스템은 다양한 프로젝트 경험과 고객 이해를 바탕으로 최적의 IT 서비스를 제공하며, 고객 만족을 넘어 감동을 전하고
                      가치를 나누는 고객과 함께 (With You)하는 기업이 되고자 합니다. <br />
                      이를 위해 '창의, 열정, 도전, 신뢰'를 갖춘 인재를 채용하고 양성하는 데 최선을 다하고 있습니다.
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="cb01">
                  <div className="tg01">
                    <figure>
                      <img src={handShake} alt="" />
                    </figure>
                    <div className="t01">
                      <b>경쟁력을 갖춘 전문기업으로의 도약</b>
                    </div>
                    <div className="t02">
                      통합 IT 서비스, 솔루션, 콘텐츠 개발, 모바일 융합서비스 사업 등 핵심사업의 선택과 집중의 경영을 통해 '한발 앞선
                      경영'으로 고객의 다양한 요구를 충족시키는 ICT 전문기업으로 도약하고 있습니다.
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="cb01">
                  <div className="tg01">
                    <figure>
                      <img src={people} alt="" />
                    </figure>
                    <div className="t01">
                      <b>창의적이고 역동적인 인재중심의 기업</b>
                    </div>
                    <div className="t02">
                      DynaSystem은 역동적이고 창의적인 기술자들을 중심으로 고객이 만족하는 안정적인 고효율 시스템을 구축하자라는 의미입니다.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* // 비전 */}

      {/* 회사소개 */}
      <div className="content02">
        <div className="container">
          <div className="intro_w01">
            <figure>
              <img src="" alt="" />
            </figure>
            <div className="title">
              항상 고객과 함께 With You !
              <br />
              고객에게 감동을 전하는 <b>ICT 전문기업</b>
            </div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      {/* // 회사소개 끝 */}

      {/* 회사정보 */}
      <div className="content03">
        <div className="container">
          <div className="tit_area01">
            <h3 className="title01">
              <span>OVER</span>VIEW
            </h3>
            <div className="line"></div>
          </div>

          <div className="info-box-wrap" data-aos="fade-up" data-aos-delay="100">
            <div className="info-box">
              <strong className="title">회사명</strong>
              <p className="desc">다이나시스템</p>
            </div>
            <div className="info-box">
              <strong className="title">설립년월</strong>
              <p className="desc">2015년 7월</p>
            </div>
            <div className="info-box">
              <strong className="title">대표이사</strong>
              <p className="desc">{dataList?.boss}</p>
            </div>
            <div className="info-box">
              <strong className="title">직원수</strong>
              <p className="desc">{dataList?.employees}명</p>
            </div>
            <div className="info-box">
              <strong className="title">매출액</strong>
              <p className="desc">{dataList?.profit}</p>
            </div>
            <div className="info-box">
              <strong className="title">주요사업</strong>
              <p className="desc">{dataList?.majorBusiness}</p>
            </div>
            <div className="info-box">
              <strong className="title">주소</strong>
              <p className="desc">서울시 영등포구 선유로49길 23 선유도역2차 IS비즈타워 7층 716호</p>
            </div>
            <div className="info-box">
              <strong className="title">홈페이지</strong>
              <p className="desc">www.dynasystem.co.kr</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
