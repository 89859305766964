import React from "react";

const SubMenu3 = () => {
  return (
    <div id="sub_visual" class="">
      <div class="subtop subtop_solution"></div>
      <div class="v_txt">
        <h2 data-splitting="">SOLUTION</h2>
        <div class="line"></div>
        <p>The Best Leader of Information Technology</p>
      </div>
    </div>
  );
};

export default SubMenu3;
