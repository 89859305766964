import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import image1 from "../../img/business/bf_ico01.png"
import image2 from "../../img/business/bf_ico02.png"
import image3 from "../../img/business/bf_ico03.png"
import image4 from "../../img/business/bf_ico04.png"
import image5 from "../../img/business/bf_ico05.png"
import image6 from "../../img/business/bf_ico06.png"
import image7 from "../../img/business/bf_ico07.png"
import image8 from "../../img/business/bf_ico08.png"
import image9 from "../../img/business/bf_ico09.png"
import image10 from "../../img/business/bf_ico10.png"
import image11 from "../../img/business/bf_ico11.png"
import image12 from "../../img/business/bf_ico12.png"
import image13 from "../../img/business/bf_ico13.png"
import image14 from "../../img/business/bf_ico16.png"
import image15 from "../../img/business/bf_ico15.png"

const Field = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'tab1');
  
  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      const nav = document.querySelector('nav');
      
      if (nav) {
        if (scroll >= 450) {
          nav.classList.add('fixed');
          nav.style.marginLeft = `-${nav.offsetWidth / 2}px`;
        } else {
          nav.classList.remove('fixed');
          nav.style.marginLeft = 'auto';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setActiveTab(searchParams.get('tab') || 'tab1');
  }, [searchParams]);

  const handleTabClick = (tab) => {
    setSearchParams({ tab });
  };

  const tabContents = {
    tab1: {
      title: "컨설팅",
      description: "축적된 노하우와 컨설팅 역량을 바탕으로 정보시스템 현황 조사, 정보화 수준 평가 분석을 통해 비지니스 전략 수립, 프로세스 혁신, IT 솔루션 도입 등 고객 가치를 높이는 최적의 정보시스템을 제안합니다.",
      subtitle: "최적의 정보시스템 구축 컨설팅",
      items: [
        {
          icon: <img src={image1}></img>,
          title: "보안컨설팅",
          description: "전산시스템과 네트워크등 모든 IT자산과 조직에 일어날 수 있는 보안 위험을 분석하고 이에 대한 대책을 수립합니다."
        },
        {
            icon: <img src={image2}></img>,
          title: "빅데이터 컨설팅",
          description: "지속적으로 증가하고 있는 다양한 빅데이터를 활용하여 비용절감, 정확한 의사결정, 운영최적화를 실현합니다."
        },
        {
            icon: <img src={image3}></img>,
          title: "클라우드 컨설팅",
          description: "검증된 클라우드 전문가와 분야별 외부 전문인력을 통해 클라우드 전분야에 대한 맞춤 컨설팅을 제공합니다.\n현황분석을 통한 클라우드 적합성을 분석하고 개선사항을 도출하여 이에 맞는 클라우드 서비스를 제시합니다."
        }
      ]
    },
    tab2: {
      title: "시스템통합",
      description: "다양한 산업분야의 기업 업무환경과 요구에 맞는 최적의 IT시스템을 진단, 설계, 구축, 통합하여 고객의 경쟁력 향상을 위한 최상의 서비스를 제공합니다.\n기업의 전략적 과제를 정보화 관점에서 진단하고, 고객의 비지니스 환경과 요구에 적합한 시스템과 네트워크 인프라를 구축하고 기존 시스템과 통합하여 보다 나은 서비스를 제공합니다.",
      items: [
        {
          icon: <img src={image4}></img>,
          title: "맞춤형 시스템 구축",
          description: "고객의 시스템을 운영 환경과 구축 목적에 최적화된 맞춤형 시스템을 개발/구축 합니다.\n또한 표준 기술 적용을 통해 향후 운영 및 유지보수성을 극대화합니다."
        },
        {
          icon: <img src={image5}></img>,
          title: "빅데이터 / DW 구축",
          description: "EDW & Big Data 분석 기술을 바탕으로 BI 구축 및 전략적 활용 컨설팅을 통해 합리적인 DW를 구축합니다.\n또한 실시간 빅데이터 기반의 보안분석, 시스템 운영 및 관제, 마케팅 및 데이터 분석을 구현합니다."
        },
        {
          icon: <img src={image6}></img>,
          title: "가상화 자원통합 구축",
          description: "가상화 솔루션을 활용하여 시스템 자원(서버, 스토리지, 네트워크 등)을 통합, 구축하여 시스템 구축비용을 절감하고 관리비용을 최소화 합니다."
        }
      ]
    },
    tab3: {
      title: "아웃소싱",
      description: "풍부한 경험과 전문인력을 통해 아웃소싱 서비스를 제공합니다. 고객사는 핵심적인 업무에 집중하여 경쟁력을 높일 수 있으며 고객 요구에 맞는 전문적인 아웃소싱 서비스를 제공받아 정보시스템을 효율적으로 활용할 수 있습니다.",
      items: [
        {
          icon: <img src={image7}></img>,
          title: "시스템 관리, 운영 및 유지보수",
          description: "IT 전분야의 전문가들이 고객사의 시스템 자원을 효율적이고 합리적으로 관리, 운영하여 고객사의 정보시스템 활용률을 높입니다."
        },
        {
          icon: <img src={image8}></img>,
          title: "금융기관 IT 아웃소싱",
          description: "다이나시스템이 보유한 핵심기술과 솔루션을 기반으로 금융기관의 내부 그룹웨어, 업무시스템 구축 및 운영을 통해 고객사 업무 호율을 극대화시키고 비용절감을 통한 이윤 추구를 극대화합니다."
        },
        {
          icon: <img src={image9}></img>,
          title: "통합보안관제",
          description: "고객사의 시스템 자원에 대한 보안 이슈를 통합적으로 관리하고 이를 관제 운영하는 서비스를 제공합니다.\n고객사는 내외부 보안 위험 없이 핵심업무에 집중하여 정보 시스템을 효과적으로 활용할 수 있습니다."
        }
      ]
    },
    tab4: {
      title: "솔루션",
      description: "최고의 IT기술력을 바탕으로 다양한 분야에서 고객의 경쟁력을 높일 수 있는 핵심적인 솔루션을 제공합니다.\n고객의 업무시간을 단축하고 정보자산을 호율적으로 활용하며 솔루션을 통해 절약된 기업의 자원을 핵심업무에 사용 할 수 있습니다.",
      items: [
        {
          icon: <img src={image10}></img>,
          title: "그룹웨어",
          description: "사용자의 관점의 편리성과 조직의 커뮤니케이션 효율성을 향상 시킬 수 있는 최적의 기능을 제공하며, 지능형 스마트 협업 환경을 제공합니다."
        },
        {
          icon: <img src={image11}></img>,
          title: "콘텐츠 관리시스템",
          description: "다수의 사이트를 통합된 관리시스템으로 운영하여 운영 효율성 및 업무 생산성을 증대합니다.\n또한 전자정부 표준 프레임워크 기반으로 시스템 유지보수 및 확장성을 극대화하고 복잡한 콘텐츠 관리 요구사항들을 반영할 수 있습니다."
        },
        {
          icon: <img src={image12}></img>,
          title: "교육 관리시스템",
          description: "누구나 손쉽게 이러닝 서비스를 이용할수 있도록 개발한 교육관리 시스템입니다.\n다양한 연동을 통해 인터넷 교육 비지니스를 위한 통합 서비스를 제공하고 있습니다."
        },
        {
          icon: <img src={image13}></img>,
          title: "물류 관리시스템",
          description: "온라인 상거래와 오프라인 상거래를 통합관리 할 수 있는 판매재고관리 솔루션으로 체게적 관리 업무를 통한 비용 절감과 기업 경쟁력을 확보할 수 있습니다."
        },
        {
          icon: <img src={image14}></img>,
          title: "의료 블록체인",
          description: "의료 정보 관리의 패러다임을 바꾸는 블록체인 기술, 환자의 개인 정보를 안전하게 보호하면서도 의료진과 연구기관이 필요한 데이터를 효율적으로 활용할 수 있도록 돕습니다."
        },
        {
          icon: <img src={image15}></img>,
          title: "DB 암호화",
          description: "다양한 공격기법을 통하여 지속되는 위협적인 정보 유출 시도로부터 고객의 중요 데이터를 효과적으로 보호하기 위한 DB 암호화 솔루션입니다.\n시스템 성능에 부하를 주지 않으면서 보한성을 보장할 수 있는 장점이 있습니다."
        }
      ]
    }
  };

  return (
    <div id="contents_in" className="bs_field">
      <div className="tit_area">
        <h3 className="title">사업분야</h3>
      </div>
      
      <div className="content01">
        <div className="container">
          <article id="cont" className="m_report tabList">
            <nav>
              <div className="container">
                <ul className="tabMenu">
                  {Object.keys(tabContents).map((tab) => (
                    <li
                      key={tab}
                      className={activeTab === tab ? 'on' : ''}
                      onClick={() => handleTabClick(tab)}
                    >
                      <span>{tabContents[tab].title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>

            <article id="tabContent">
              {Object.entries(tabContents).map(([key, content]) => (
                <article 
                  key={key} 
                  id={key} 
                  style={{ display: activeTab === key ? 'block' : 'none' }}
                >
                  <div className="titg01">
                    <h2>{content.title}</h2>
                    <p className="t01">{content.description}</p>
                  </div>
                  
                  {content.subtitle && (
                    <div className="co_tit">
                      {content.subtitle}
                    </div>
                  )}
                  
                  <div className={`in_cont ${key === 'tab4' ? 'col2' : ''}`}>
                    {content.items.map((item, index) => (
                      <div key={index} className="co_box">
                        <div className="w01">
                          <div className="circle">
                            {item.icon}
                          </div>
                        </div>
                        <div className="w02">
                          <div className="tg01">
                            <div className="t01">{item.title}</div>
                            <div className="t02">{item.description}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </article>
              ))}
            </article>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Field;