import React from "react";
import ceo_img_pc from "../../img/company/ceo_img_pc.jpg";
import ceo_img_m from "../../img/company/ceo_img_m.jpg";

const Ceo = () => {
  return (
    <div id="contents_in" className="ceo">
      <div className="tit_area">
        <h3 className="title">CEO 인사말</h3>
      </div>

      <div className="content01">
        <div className="container">
          <div className="wg01">
            <div className="w01">
              <picture>
                <source srcset={ceo_img_m} media="(max-width: 920px)" />
                <img src={ceo_img_pc} alt="ceo" />
              </picture>
            </div>
            <div className="w02">
              <div className="tg01">
                <div className="t01">고객 여러분 안녕하십니까?</div>
                <div className="t02">
                  방문해주신 여러분께 진심으로 감사드립니다. 여러분과의 만남을 더없이 기쁘고 소중하게 생각합니다.
                </div>
                <div className="t03">
                  21세기 대한민국 IT 산업의 미래를 개척해 나가는 다이나시스템은 항상 여러분의 곁에 있겠습니다.
                </div>
                <div className="t03">
                  저희들은 창업이래 끊임없이 발전하는 미래 IT 기술에 폭넓게 대응하며 변화와 혁신을 통해 업계의
                  기술분야를 리드하는 IT SOLUTION PROVIDER로써의 위상을 다져왔습니다.
                </div>
                <div className="t03">
                  저희 임직원 일동은 그간 축적한 기반 기술을 바탕으로 현재 뿐만 아니라 미래 IT 기술에 있어서 가장 큰
                  화두이자, 미래를 변화할 수 있는 열쇠로써 빅데이터, IOT, 클라우드 서비스 등을 통합적으로 구현할 수 있는
                  전문기업으로써 다양한 솔루션과 서비스로 세상을 보다 즐겁고 보다 이롭게 하고자 합니다.
                </div>
                <div className="t03">
                  고객중심의 가치관을 다이나시스템 임직원 모두가 공유하여, 고객의 생각을 현실로 변화시키는 과정을 통해
                  고객으로부터 저희가 속한 사회의 모든 구성원에 이르기까지 삶의 질이 풍요로워 질 수 있도록 최선을 다할
                  것을 약속드립니다.
                </div>
                <div className="t03">
                  다이나시스템은 항상 여러분의 곁에서 여러분의 생각을 현실로 만들어드리겠습니다.
                </div>
                <div className="sign_ceo">
                  <span>대표이사</span> 임경호
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ceo;
