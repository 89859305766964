import React, { useEffect } from "react";
import footer from "./imageCommon/footer.png";
import pdf from "./imageCommon/pdf.png";
import top_btn from "./imageCommon/topBtn.png";

const Footer = () => {
  useEffect(() => {
    const handleScroll = () => {
      //    const quick = document.getElementById('quick');
      //    const topBtn = document.querySelector('.top_btn');
      //    if (window.scrollY > 500) {
      //        quick.classList.add('on');
      //        topBtn.style.display = 'block';
      //    } else {
      //        quick.classList.remove('on');
      //        topBtn.style.display = 'none';
      //    }
    };

    const handleTopBtn = (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    window.addEventListener("scroll", handleScroll);
    document.querySelector(".top_btn").addEventListener("click", handleTopBtn);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.querySelector(".top_btn").removeEventListener("click", handleTopBtn);
    };
  }, []); // 빈 배열: 마운트 시 1회만 실행

  return (
    <footer id="foot" className="">
      <div className="container">
        <div className="footwg">
          <div className="footw01">
            <div className="logo">
              <img src={footer} alt="로고" />
              <br />
            </div>
            <div className="copyright">
              <span>대표 :</span> 임경호
              <br />
              <span>Address </span> 서울시 영등포구 선유로49길 23 선유도역2차 IS비즈타워 7층 716호
              <br />
              <span>TEL</span> 02-865-8684
              <br />
              <br />
              <br />
              COPYRIGHTⓒ DYNAsystem. 2024 ALL RIGHTS RESERVED.
            </div>
          </div>
        </div>
      </div>

      {/* 퀵메뉴 버튼, 탑 스크롤 버튼 */}
      <div id="quick">
        <ul>
          <li className="onlineBtn">
            <a href="/Introduction.pdf" download="다이나시스템 회사소개서.pdf">
              <img src={pdf} alt="백서" />
              <span>회사소개서</span>
            </a>
          </li>
          <li className="top_btn">
            <a
              style={{
                background: `#fff url(${top_btn}) center no-repeat`,
                border: "1px solid #333",
              }}
              href="#" 
            ></a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
