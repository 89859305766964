import React from 'react';
import check from '../../img/business/icon_check.png'
import computer from '../../img/business/bs_area_img02.jpg'
import up from '../../img/business/bs_area_img01.jpg'
import cute from '../../img/business/bs_area_img03.jpg'

const Area = () => {
  return (
    <div id="contents_in" className="bs_area">
      <div className="tit_area">
        <h3 className="title">사업영역</h3>
      </div>
      
      <div className="content01">
        <div className="container">
          <div className="title">  
            <strong>DynaSystem</strong>은
            <p>역동적, 원동력을 내재 하고 있는 개발자들로 안정적인 시스템을 구축하자라는 모토를 가지고 2015년 창립 후 금융전산과 기업 및 공공 맞춤형 SI,SM 그리고 DW 구축 및 개발에 관한 컨설팅을 수행하고 있는 개발 전문 기업입니다.</p>
          </div>
        </div>
        <div className="bg-video">
          <video className="bg-video__content" autoPlay muted loop>
          <source src={process.env.PUBLIC_URL + "/videos/bs_area_v01.mp4"} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
      
      <div className="content02">
        <div className="container">
          <ul>
            <li>
              <div className="tg01">
                <div className="tno">
                  01
                </div>
                <div className="t01">
                  금융사업 영역
                </div>
                <div className="line">          
                </div>
                <div className="t02">
                  <img src={check} alt="" />증권 금융 솔루션(주식담보대출 리스크 관리 시스템 - RMS)<br />
                  <img src={check} alt="" />차세대 경영정보 시스템 구축 및 운영<br />
                  <img src={check} alt="" />그룹웨어 및 내부 업무시스템 구축 및 운영<br />
                  <img src={check} alt="" />MSA 구축 및 성능진단/컨설팅<br />
                </div>
              </div>
              <div className="fg01">
                <img src={up} alt="" />
              </div>
            </li>
            <li className="row02">
              <div className="tg01">
                <div className="tno">
                  02
                </div>
                <div className="t01">
                  공공사업 영역
                </div>
                <div className="line">          
                </div>
                <div className="t02">
                <img src={check} alt="" />홈페이지, 포털 통합 및 구축/운영<br />
                <img src={check} alt="" />스마트 교육시스템 구축 및 운영<br />
                <img src={check} alt="" />내부 업무시스템 맞춤 개발 및 커스터마이징<br />
                <img src={check} alt="" />빅데이터 및 DW 구축 및 컨설팅
                </div>
              </div>
              <div className="fg01">
                <img src={computer} alt="" />
              </div>
            </li>
            <li>
              <div className="tg01">
                <div className="tno">
                  03
                </div>
                <div className="t01">
                  일반기업 영역
                </div>
                <div className="line">          
                </div>
                <div className="t02">
                  <img src={check} alt="" />홈페이지 구축 및 서버 호스팅<br />
                  <img src={check} alt="" />ERP 시스템 구축 및 운영<br />
                  <img src={check} alt="" />전자 결제 시스템 구축 및 운영<br />
                  <img src={check} alt="" />물류관리시스템 구축 및 컨설팅<br />
                </div>
              </div>
              <div className="fg01">
                <img src={cute} alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Area;