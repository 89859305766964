import React from "react";

const SubMenu = () => {
  return (
    <div id="sub_visual" className="">
      <div className="subtop subtop_about"></div>
      <div className="v_txt">
        <h2 data-splitting="">COMPANY</h2>
        <div className="line"></div>
        <p>The Best Leader of Information Technology</p>
      </div>
    </div>
  );
};

export default SubMenu;
