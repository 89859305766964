import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav1 from "view/common/Nav1";
import Main from "view/main/Main";
import DefaultLayout from "view/common/DefaultLayout";
import SubMenu from "./view/common/SubMenu";
import Aos from "aos";

import Ceo from "./view/menu01_company/Ceo";
import About from "./view/menu01_company/About";
import Philosophy from "./view/menu01_company/Philosophy";
import History from "./view/menu01_company/History";
import Organization from "./view/menu01_company/Organization";
import Location from "./view/menu01_company/Location";
import Nav2 from "./view/common/Nav2";
import SubMenu2 from "./view/common/SubMenu2";
import Filed from "./view/menu02_business/Field";
import Area from "./view/menu02_business/Area";
import Partner from "./view/menu02_business/Partner";
import { QueryConfig } from "config";
import GroupWare from "./view/menu03_solution/GroupWare";
import Nav3 from "./view/common/Nav3";
import SubMenu3 from "view/common/SubMenu3";
import Contents from "view/menu03_solution/Contents";
import Education from "view/menu03_solution/Education";
import Distribution from 'view/menu03_solution/Distribution';
import BlockChain from "view/menu03_solution/BlockChain";

import SubMenu4 from "view/common/SubMenu4";
import Recruit from "view/menu04_recruit/Recruit";
import Nav4 from "view/common/Nav4";

const App = () => {
  useEffect(() => {
    Aos.init();
  });

  return (
    /* url 뎁스를 1로 해야 헤더오류가 안남 */
    <QueryConfig>
      <BrowserRouter>
        <Routes>
          {/* 메인페이지 url */}
          <Route key={"/"} path="/" element={<DefaultLayout content={<Main />} />} />

          {/* 회사소개 대분류 url */}
          <Route path="/about" element={<DefaultLayout subMenu={<SubMenu />} nav={<Nav1 />} content={<About />} />} />
          <Route path="/ceo" element={<DefaultLayout subMenu={<SubMenu />} nav={<Nav1 />} content={<Ceo />} />} />
          <Route path="/philosophy" element={<DefaultLayout subMenu={<SubMenu />} nav={<Nav1 />} content={<Philosophy />} />} />
          <Route path="/history" element={<DefaultLayout subMenu={<SubMenu />} nav={<Nav1 />} content={<History />} />} />
          <Route path="/organization" element={<DefaultLayout subMenu={<SubMenu />} nav={<Nav1 />} content={<Organization />} />} />
          <Route path="/location" element={<DefaultLayout subMenu={<SubMenu />} nav={<Nav1 />} content={<Location />} />} />

          {/* 사업소개 대분류 url */}
          <Route path="/field" element={<DefaultLayout subMenu={<SubMenu2 />} nav={<Nav2 />} content={<Filed />} />} />
          <Route path="/area" element={<DefaultLayout subMenu={<SubMenu2 />} nav={<Nav2 />} content={<Area />} />} />
          <Route path="/partner" element={<DefaultLayout subMenu={<SubMenu2 />} nav={<Nav2 />} content={<Partner />} />} />

          {/* 솔루션 대분류 url */}
          <Route path="/groupWare" element={<DefaultLayout subMenu={<SubMenu3 />} nav={<Nav3 />} content={<GroupWare />} />} />
          <Route path="/contents" element={<DefaultLayout subMenu={<SubMenu3 />} nav={<Nav3 />} content={<Contents />} />} />
          <Route path="/education" element={<DefaultLayout subMenu={<SubMenu3 />} nav={<Nav3 />} content={<Education />} />} />
          <Route path="/distribution" element={<DefaultLayout subMenu={<SubMenu3 />} nav={<Nav3 />} content={<Distribution />} />} />
          <Route path="/blockchain" element={<DefaultLayout subMenu={<SubMenu3 />} nav={<Nav3 />} content={<BlockChain />} />} />

          {/* 채용정보 대분류 url */}
          <Route path="/recruit" element={<DefaultLayout subMenu={<SubMenu4 />} nav={<Nav4 />} content={<Recruit />} />} />
        </Routes>
      </BrowserRouter>
    </QueryConfig>
  );
};

export default App;
