import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import recruitIco01 from "../../img/recruit/recruit_ico01.png";
import recruitIco02 from "../../img/recruit/recruit_ico02.png";
import recruitIco03 from "../../img/recruit/recruit_ico03.png";
import recruitProcessIco01 from "../../img/recruit/recruit_process_ico01.png";
import recruitProcessIco02 from "../../img/recruit/recruit_process_ico02.png";
import recruitProcessIco03 from "../../img/recruit/recruit_process_ico03.png";
import recruitProcessIco04 from "../../img/recruit/recruit_process_ico04.png";
import recruitProcessIco05 from "../../img/recruit/recruit_process_ico05.png";
import recruitPpIco01 from "../../img/recruit/recruit_pp_ico01.png";
import recruitPpIco02 from "../../img/recruit/recruit_pp_ico02.png";
import recruitPpIco03 from "../../img/recruit/recruit_pp_ico03.png";
import recruitKkIco01 from "../../img/recruit/recruit_kk_ico01.png";
import recruitKkIco02 from "../../img/recruit/recruit_kk_ico02.png";
import recruitKkIco03 from "../../img/recruit/recruit_kk_ico03.png";
import recruitKkIco04 from "../../img/recruit/recruit_kk_ico04.png";



// 섹션 타이틀 컴포넌트
const SectionTitle = ({ mainText, subText, description }) => (
  <div className="tit_area01">
    <h3 className="title01">
      {mainText && <span>{mainText}</span>} {subText}
    </h3>
    {description && <p>{description}</p>}
    <div className="line"></div>
  </div>
);

// 인재상 카드 컴포넌트
const TalentCard = ({ imgSrc, title, description }) => (
  <li>
    <div className="cb01">
      <div className="tg01">
        <figure>
          <img src={imgSrc} alt="" />
        </figure>
        <div className="t01">
          <b>{title}</b>
        </div>
        <div className="t02">{description}</div>
      </div>
    </div>
  </li>
);

// 채용절차 아이템 컴포넌트
const ProcessStep = ({ step, title, imgSrc, isLast }) => (
  <div>
    <dl>
      <dt>
        <span>STEP {step}</span>
        {title}
      </dt>
      <dd>
        <img src={imgSrc} alt="" />
      </dd>
    </dl>
    {!isLast && (
      <div className="arrow">
        <span className="m_scroll_arrows unu"></span>
        <span className="m_scroll_arrows doi"></span>
        <span className="m_scroll_arrows trei"></span>
      </div>
    )}
  </div>
);

// 인사제도/복리후생 카드 컴포넌트
const BenefitCard = ({ type, imgSrc, title, description }) => {
  if (type === "culture") {
    return (
      <div className="box_item">
        <div className="img_box">
          <img src={imgSrc} alt="" />
        </div>
        <div className="tg01">
          <div className="t01">{title}</div>
          <ul className="text-list">
            <li>{description}</li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <li>
      <figure>
        <img src={imgSrc} alt="" />
      </figure>
      <div className="txt">
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </li>
  );
};

const Recruit = () => {
  const [paramString] = useSearchParams();

  useEffect(() => {
    const param = paramString.get("param");
    if (param) {
      const element = document.getElementById(`recruit${param}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [paramString]);

  // 인재상 데이터
  const talents = [
    {
      imgSrc: recruitIco01,
      title: "최고의 실력",
      description: "끊임없는 자기개발과 빠른 실행력을 바탕으로 일을 통해 성장하는 역동적인 인재",
    },
    {
      imgSrc: recruitIco02,
      title: "고객의 신뢰",
      description: "고객을 최우선으로 생각하는 자세로 고객의 가치를 높이는 인재",
    },
    {
      imgSrc: recruitIco03,
      title: "일에 대한 오너십",
      description: "긍정적인 마인드와 도전적인 자세로 목표를 달성하는 책임감 강한 인재",
    },
  ];

  // 채용절차 데이터
  const processSteps = [
    { step: 1, title: "채용공고", imgSrc: recruitProcessIco01 },
    { step: 2, title: "입사지원", imgSrc: recruitProcessIco02 },
    { step: 3, title: "서류전형", imgSrc: recruitProcessIco03 },
    { step: 4, title: "면접전형", imgSrc: recruitProcessIco04 },
    { step: 5, title: "최종합격", imgSrc: recruitProcessIco05 },
  ];

  // 인사제도 데이터
  const cultureBenefits = [
    {
      imgSrc: recruitPpIco01,
      title: "수평적이고 창의적인 조직문화 형성",
      description: "직위체계를 폐지하고 Manager제도 도입으로 개인역량 발휘 극대화 및 실행력 증대",
    },
    {
      imgSrc: recruitPpIco02,
      title: "공정한 평가, 함께 성장하는 길",
      description: "공정한 성과 평가를 위한 연중 상시 PR&C (People Review & Coaching)",
    },
    {
      imgSrc: recruitPpIco03,
      title: "스마트한 근무 환경과 특별한 휴가로 직원의 행복을 더하다",
      description: "Smart Work 제도 도입 등으로 업무 생산성을 높이고 Refresh 휴가(10년 근무시 특별 휴가/휴가비 지원)",
    },
  ];

  // 복리후생 데이터
  const welfareBenefits = [
    {
      imgSrc: recruitKkIco01,
      description: "경조금 및 휴가지원",
    },
    {
      imgSrc: recruitKkIco02,
      description: "점심 식대 제공<br>(야근 시 저녁 식대 제공)",
    },
    {
      imgSrc: recruitKkIco03,
      description: "도서구입 및<br>온라인 교육 수강료 지원",
    },
    {
      imgSrc: recruitKkIco04,
      description: "조직문화 활성화를 위한<br>행사 및 동호회 지원",
    },
  ];

  return (
    <div id="contents_in" className="recruit">
      <div id="recruit1"></div>
      <div id="title1" className="tit_area">
        <h3 className="title">인재상/채용안내</h3>
      </div>

      {/* 인재상 */}
      <div className="content01">
        <div className="container">
          <div className="title">
            최고의 실력과 오너십으로 신뢰를 얻는 인재
            <p>
              다이나시스템은 고객을 최우선으로 새악하는 자세로 고객의 가치를 높이며
              <br />
              끊임없는 자기 개발과 빠른 실행력으로 일에 대한 가치를 높이는 책임감 강한 인재를 찾습니다.
            </p>
          </div>
          <div className="cbox_w">
            <ul>
              {talents.map((talent, index) => (
                <TalentCard key={index} {...talent} />
              ))}
            </ul>
          </div>
        </div>
        <div id="recruit2"></div>
      </div>

      {/* 채용절차 */}
      <div className="content02" id="recruit02">
        <div id="title2" className="container">
          <SectionTitle
            mainText="채용"
            subText="절차"
            description="다이나시스템은 SI, SM, 솔루션 사업을 주력으로 하고 있는 Total IT Service 업체로서 정보화 사회를 이끌어 나갈 진취적이고 창의적인 인재를 모집합니다."
          />
          <div className="recruit_process grayBg">
            <div className="recruit_processList">
              {processSteps.map((step, index) => (
                <ProcessStep key={index} {...step} isLast={index === processSteps.length - 1} />
              ))}
            </div>
          </div>
        </div>
        <div id="recruit3"></div>
      </div>

      {/* 인사제도 */}
      <div className="content03" id="recruit03">
        <div id="title3" className="container">
          <SectionTitle mainText="인사" subText="제도" />
          <div className="box_list01">
            {cultureBenefits.map((benefit, index) => (
              <BenefitCard key={index} type="culture" {...benefit} />
            ))}
          </div>
        </div>
      </div>

      {/* 복리후생 */}
      <div className="content03" id="recruit4">
        <div id="title4" className="container">
          <SectionTitle mainText="복리" subText="후생" />
          <div className="box_list02">
            <ul>
              {welfareBenefits.map((benefit, index) => (
                <BenefitCard key={index} type="welfare" {...benefit} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruit;
