import React, { useState } from "react";
import check from '../../img/business/icon_check.png';
import groupImg01 from '../../img/solution/group_img01.png';
import groupico01 from '../../img/solution/group_ico01.png';
import groupico02 from '../../img/solution/group_ico02.png';
import groupico03 from '../../img/solution/group_ico03.png';
import groupico04 from '../../img/solution/group_ico04.png';
import groupico05 from '../../img/solution/group_ico05.png';
import groupico06 from '../../img/solution/group_ico06.png';
import groupico07 from '../../img/solution/group_ico07.png';
import groupico08 from '../../img/solution/group_ico08.png';
import groupico09 from '../../img/solution/group_ico09.png';
import groupico10 from '../../img/solution/group_ico10.png';
import groupico11 from '../../img/solution/group_ico11.png';
import groupico12 from '../../img/solution/group_ico12.png';
import groupico13 from '../../img/solution/group_ico13.png';
import groupico14 from '../../img/solution/group_ico14.png';

const TabContent = ({ title, description, features, iconPath }) => (
  <div className="wg01">
    <div className="w01">
      <div className="t01" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="t02">
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <i className="fi fi-rr-angle-double-small-right"></i> {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
    <div className="w02">
      <div className="pic_box">
        <img src={iconPath} alt="" />
      </div>
    </div>
  </div>
);

const TabSection = ({ title, tabs, contentType }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="content02">
      <div className="container">
        <div className="tit_area01">
          <h3 className="title01">
            <span>{title.split(" ")[0]}</span> {title.split(" ")[1]}
          </h3>
          <div className="line"></div>
        </div>

        <article id="cont" className="m_report tabList">
          <nav>
            <div className="container">
              <ul className={`tabMenu type${contentType}`}>
                {tabs.map((tab, index) => (
                  <li key={index} className={activeTab === index ? "on" : ""} onClick={() => setActiveTab(index)}>
                    <span>{tab.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </nav>

          <article id="tabContent" className={`tabContent type${contentType}`}>
            {tabs.map((tab, index) => (
              <article key={index} id={`tab${index + 1}`} style={{ display: activeTab === index ? "block" : "none" }}>
                <TabContent {...tab.content} />
              </article>
            ))}
          </article>
        </article>
      </div>
    </div>
  );
};

const GroupWare = () => {
  // 업무지원 탭 데이터
  const businessSupportTabs = [
    {
      name: "전자결재(기업)",
      content: {
        title: "전자문서 <span>표준규격 및 신사무관리규정을 준수</span>하며 문서유통을 지원합니다.",
        features: [
          "한글 기안기와 웹 기안기 제공 (정부 및 공공기관 전용)",
          "내부결재/대내문서/대외문서(전자, 민원, 기타) 결재유형",
          "일괄 기안 기능 제공",
          "기록물관리를 통한 완결문서의 편철/인계/인수",
          "문서 접수과/ 처리과 업무지원",
        ],
        iconPath: groupico01,
      },
    },
    {
      name: "전자결재(공공)",
      content: {
        title: "기업의 업무환경에 최적화된 전자결재로 <span>결재시간 단축, 결재문서의 관리</span> 등 업무를 개선합니다.",
        features: [
          "다양한 결재 프로세스 및 옵션 제공",
          "보안결재, 부재설정, 대결지정, 걸재 기한 알림",
          "문서 및 결재선 변경 및 변경 이력 확인",
          "역할에 따른 위임 전결 관리기능",
          "폼 빌더 방식을 통한 결재 양식의 WYSIWYG 실현",
          "외부 시스템 연동을 위한 연동API 및 스크립트 편집기 제공",
        ],
        iconPath: groupico02,
      },
    },
    {
      name: "문서함",
      content: {
        title: "<span>다양한 문서와 자료를 체계적으로 관리</span>하여 신속하게 검색 및 활용 할 수 있습니다.",
        features: [
          "다차원 문서 분류 기능",
          "문서의 버전관리 및 반입/반출기능",
          "문서별 세부권한 지정",
          "문서 등록 승인 기능",
          "종결된 결재문서 자동/수동 이관",
        ],
        iconPath: groupico03,
      },
    },
    {
      name: "근태관리",
      content: {
        title: "출근, 퇴근, 휴가, 출장 등 <span>사내 인적자원 관리</span>를 보다 편리하게 할 수 있습니다.",
        features: [
          "지문인식기와 연동하여 출퇴근 기록",
          "근태신청시 전자결재 연동 승인",
          "구성원의 부재 현황 파악",
          "사용자별 평균 출퇴근 시간 등 통계",
          "근태보고서 조회, 출력, 엑셀 저장",
        ],
        iconPath: groupico04,
      },
    },
    {
      name: "주소록",
      content: {
        title: "사용자 및 조직과 관련된 <span>연락처를 관리하고 공유</span>할 수 있습니다.",
        features: [
          "부서 연락처 공유",
          "연락처 상세등록, 빠른 등록지원",
          "중복연락처 체크를 통한 주소록 정리기능",
          "주소록 내보내기 / 아웃룩 주소록 가져오기",
          "SMS 연동, 명함인식기 연동가능",
        ],
        iconPath: groupico05,
      },
    },
  ];

  // 협업지원 탭 데이터
  const collaborationTabs = [
    {
      name: "협업관리",
      content: {
        title: "협업관리는 그룹 업무에 필수적인 <span>공유, 협업 소통을 위한 공간</span>입니다.",
        features: [
          "프로젝트 생성 및 관리",
          "팀원 업무의 진행 현황 및 수정이력 조회",
          "스페이스 간 전부 또는 일부 공유 시스템 지원",
          "프로젝트 관련 메일, 전자결재 연계",
          "공유자료, 산출물 관리 위한 게시판/문서함 이관",
        ],
        iconPath: groupico06,
      },
    },
    {
      name: "자원예약",
      content: {
        title: "회의실, 차량 등의 자원에 대해 <span>예약을 하고 현황을 기간별로 조회</span>할 수 있습니다.",
        features: [
          "예약제, 승인제 자원관리",
          "자원별 사용 권한 등록",
          "자원예약과 동시에 자동 일정등록",
          "자원별 일일/주간/월간 예약현황 조회",
          "예약, 승인대기, 반납대기/지연 현황 캘린더 및 카운터",
        ],
        iconPath: groupico07,
      },
    },
    {
      name: "일정관리",
      content: {
        title: "개인 또는 조직별로 <span>일정을 공유하여 효율적이고 계획적인 업무처리</span>가 가능합니다.",
        features: [
          "Ajax 기반으로 동적 사용자 UI 제공",
          "구글 및 아웃룩 일정관리와 동기화",
          "일정 알림 기능",
          "일정 등록 시 자원예약 연동",
          "일일, 주간, 월간 일정 및 할일, 기념일 관리",
        ],
        iconPath: groupico08,
      },
    },
    {
      name: "파일함",
      content: {
        title: "개인폴더, 공용폴더, 외부폴더를 통해 <span>파일을 공유하는 웹하드</span> 기능입니다.",
        features: [
          "폴더 용량관리 기능",
          "폴더 내 업/다운로드 등 활동 알림",
          "모바일 스캐너와 연동된 스캔보관함",
          "그룹웨어 내 첨부파일 등록시 파일함에서 첨부 지원",
          "윈도우탐색기 인터페이스 적용한 파일함 탐색기 제공",
        ],
        iconPath: groupico09,
      },
    },
  ];

  // 커뮤니티 탭 데이터
  const communityTabs = [
    {
      name: "전자우편",
      content: {
        title: "한화금융그룹에 납품했던 경험으로 <span>안정성이 검증된 메일</span>을 개발하여 제공합니다.",
        features: [
          "대용량 메일 발송 기능",
          "메일 내 메모 작성기능",
          "승인메일 및 보안메일로 사내 보안 실현",
          "계층형 개인 편지함, 메일 자동분류 기능",
          "메일 수발신 로그조회 및 백업관리기능",
        ],
        iconPath: groupico10,
      },
    },
    {
      name: "게시판",
      content: {
        title: "<span>다양한 게시판 속성과 항목을 직접 디자인할 수 있는 툴을 제공</span>하여 업무 활용도를 높였습니다.",
        features: [
          "업무에 적합한 목록 및 조회화면 디자인",
          "중요공지, 게시기한, 게시 알림 기능 제공",
          "리스트, 라디오버튼, 체크박스 등 다양한 컬럼 형식 지원",
          "게시판형, 자료실형, 뉴스형, 앨범형의 View 화면",
          "익명, 지식, 핫라인, 승인, 대여/신청 등의 20여가지 속성의 게시판",
        ],
        iconPath: groupico11,
      },
    },
    {
      name: "쪽지",
      content: {
        title: "메일 전송을 대신하여 <span>쉽고 빠르게 메시지를 전송</span>하며 알림을 받을 수 있습니다.",
        features: [
          "받는사람, 참조자, 내용, 첨부파일 전송 기능",
          "메신저 쪽지 알림 및 모바일 Push 알림",
          "개인쪽지함 추가 및 백업",
          "조직도, 마이리스트(자주 연락하는 사용자)연동",
          "모바일 연동으로 시간 장소 제약없는 메시지 전달",
        ],
        iconPath: groupico12,
      },
    },
    {
      name: "설문조사",
      content: {
        title: "조직 내/외부 <span>사용자를 대상으로 의견을 수렴</span>하여 조직을 개선할 수 있습니다.",
        features: [
          "메일을 통한 외부 설문조사",
          "다양한 설문 문항 및 설문문항 분기 기능",
          "익명 및 답변 공개여부 선택",
          "설문결과 통계 제공, 엑셀 저장",
          "기존설문 재사용 기능",
        ],
        iconPath: groupico13,
      },
    },
    {
      name: "동호회",
      content: {
        title: "동호회 운영을 통해 <span>사내 문화를 개선</span>할 수 있습니다.<br>(포털 사이트에서 제공하는 카페 형식)",
        features: [
          "멤버 등급, 스텝, 메뉴 관리",
          "동호회 분류, 랭킹 제공",
          "커버스토리, 메뉴, 스킨, 레이아웃 설정",
          "등급별 동호회 할당용량 설정",
          "방문현황, 멤버현황, 가입탈퇴현황 등 각종 통계",
        ],
        iconPath: groupico14,
      },
    },
  ];

  return (
    <div id="contents_in" className="solution group">
      <div className="tit_area">
        <h3 className="title">그룹웨어</h3>
      </div>
      <div className="container">
        <div className="txt_box">
          축적된 경험과 노하우를 바탕으로 고객의 업무와 요구사항을 면밀히 분석하여 탄생한 다이나시스템 그룹웨어의 주요기능을 소개합니다.
        </div>
      </div>
      <div className="content01">
        <div className="container">
          <div className="cont_box">
            <div className="tg01">
              <div className="t01">
                Why <span>DYNASYSTEM</span> GROUPWARE?
              </div>
              <div className="line">
              </div>
              <div className="t02">
                <img src={check} alt=""/>Non-Active 환경<br/>
                <img src={check} alt=""/>웹 표준으로 개발되어 크로스브라우징 지원<br/>
                <img src={check} alt=""/>100% 자바 기반으로 개발되어 다양한 OS 플랫폼 지원<br/>
                <img src={check} alt=""/>그룹사 버전(Multi-Company) 구축 시 계열사간 그룹공지, 결재, 협업 연계<br/>
                <img src={check} alt=""/>비밀번호 암호화 전송, 중복 로그인 체크 및 SMS 인증, IP 필터링 등 강력한 보안<br/>
              </div>
            </div>
            <div className="fg01">
              <img src={groupImg01} alt=""/>
            </div>
          </div>
        </div>
      </div>
      <TabSection title="업무지원 기능" tabs={businessSupportTabs} contentType="01" />
      <TabSection title="협업지원 기능" tabs={collaborationTabs} contentType="02" />
      <TabSection title="커뮤니티 기능" tabs={communityTabs} contentType="03" />
    </div>
  );
};

export default GroupWare;
