import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const Nav4 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isActive = (path, paramKey = '', paramValue = '') => {
        if (location.pathname !== path) return false;
        if (!paramKey) return true; // 파라미터가 없으면 path만 비교
        return searchParams.get(paramKey) === paramValue; // 특정 파라미터 값이 일치하는지 비교
    };

    return (
    <div id="lnb">
        <div className="lnb_w">	
            <ul >
            <li><a className={isActive("/recruit","param","1") ? "on" : ""} onClick={()=>{navigate('/recruit?param=1')}} >인재상</a></li>
            <li><a className={isActive("/recruit","param","2") ? "on" : ""} onClick={()=>{navigate('/recruit?param=2')}} >채용절차</a></li>    
            <li><a className={isActive("/recruit","param","3") ? "on" : ""} onClick={()=>{navigate('/recruit?param=3')}} >인사제도</a></li>            
            <li><a className={isActive("/recruit","param","4") ? "on" : ""} onClick={()=>{navigate('/recruit?param=4')}} >복리후생</a></li>            
            </ul>
        </div>
    </div>
    );
};

export default Nav4;