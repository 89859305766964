import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import consulting from "../../img/main_v_q_img01.png";
import si from "../../img/main_v_q_img02.png";
import outsosing from "../../img/main_v_q_img03.png";
import solution from "../../img/main_v_q_img04.png";
import soga1 from "../../img/m_business_img01.png";
import soga2 from "../../img/m_business_img02.png";
import soga3 from "../../img/m_business_img03.png";
import soga4 from "../../img/m_business_img04.png";
import sec02_1 from "../../img/ico_box01.png";
import sec02_2 from "../../img/ico_box02.png";
import sec02_3 from "../../img/ico_box03.png";
import sec02_4 from "../../img/ico_box04.png";
import sec02_6 from "../../img/ico_box06.png";
import monitor from "../../img/slide_monitor.png";
import sec03_1 from "../../img/slide_img01.png";
import sec03_2 from "../../img/slide_img02.png";
import sec03_3 from "../../img/slide_img03.png";
import sec03_4 from "../../img/slide_img04.png";
import sec03_5 from "../../img/slide_img05.png";
import cbanner01 from "../../img/banner/c_banner01.jpg";
import cbanner02 from "../../img/banner/c_banner02.jpg";
import cbanner03 from "../../img/banner/c_banner03.jpg";
import cbanner04 from "../../img/banner/c_banner04.jpg";
import cbanner05 from "../../img/banner/c_banner05.jpg";
import cbanner06 from "../../img/banner/c_banner06.jpg";
import cbanner07 from "../../img/banner/c_banner07.jpg";
import cbanner08 from "../../img/banner/c_banner08.jpg";
import cbanner09 from "../../img/banner/c_banner09.jpg";
import cbanner10 from "../../img/banner/c_banner10.jpg";
import pbanner01 from "../../img/banner/p_banner01.jpg";
import pbanner02 from "../../img/banner/p_banner02.jpg";
import pbanner03 from "../../img/banner/p_banner03.jpg";
import pbanner04 from "../../img/banner/p_banner04.jpg";
import pbanner05 from "../../img/banner/p_banner05.jpg";
import pbanner06 from "../../img/banner/p_banner06.jpg";
import pbanner07 from "../../img/banner/p_banner07.jpg";
import pbanner08 from "../../img/banner/p_banner08.jpg";
import pbanner09 from "../../img/banner/p_banner09.jpg";
import pbanner10 from "../../img/banner/p_banner10.jpg";

const Main = () => {
  // 퀵메뉴, 탑 버튼 스크롤 기능
  useEffect(() => {
    const handleScroll = () => {
      if ($(window).scrollTop() > 500) {
        $("#quick").addClass("on");
        $(".top_btn").fadeIn(500);
      } else {
        $("#quick").removeClass("on");
        $(".top_btn").fadeOut(500);
      }
    };

    const handleTopButton = (e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        400
      );
    };

    $(window).on("scroll", handleScroll);
    $(".top_btn").on("click", handleTopButton);

    return () => {
      $(window).off("scroll", handleScroll);
      $(".top_btn").off("click", handleTopButton);
    };
  }, []);

  // AOS 초기화
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      easing: "ease-in-out",
      duration: 700,
    });
  }, []);

  return (
    <>
      <div id="visual">
        <ul className="sub_imgbox">
          <li>
            <div className="section cover01">
              <div className="bg-video">
                <video className="bg-video__content" autoPlay muted loop>
                  <source src={"/videos/mainVideo.mp4"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="v_img"></div>

              <div className="container">
                <div className="v_txt">
                  <div className="tg01">
                    <p>The Best Leader of Information Technology</p>
                  </div>
                  <h3>DYNA SYSTEM</h3>
                  <span>
                    다양한 산업 분야의 기업에 최적의 IT 시스템을 진단, 설계, 구축 및 통합하여 경쟁력 향상을 위한
                    서비스를 제공합니다.
                    <br />
                    기업의 전략적 정보화 관점에서 고객의 비즈니스 환경과 요구에 맞는 시스템과 네트워크 인프라를 구축하고
                    <br />
                    기존 시스템과 통합하여 더 나은 서비스를 제공합니다.
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="main_v_quick">
          <ul className="quick_wg">
            <li>
              <a href="/field?tab=tab1">
                <div className="cbw">
                  <div className="cbox">
                    <div className="ig01">
                      <img src={consulting} alt="" />
                    </div>
                    <div className="tg01">
                      <div className="t01">컨설팅</div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/field?tab=tab2">
                <div className="cbw">
                  <div className="cbox">
                    <div className="ig01">
                      <img src={si} alt="" />
                    </div>
                    <div className="tg01">
                      <div className="t01">시스템 통합</div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/field?tab=tab3">
                <div className="cbw">
                  <div className="cbox">
                    <div className="ig01">
                      <img src={outsosing} alt="" />
                    </div>
                    <div className="tg01">
                      <div className="t01">IT 아웃소싱</div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/field?tab=tab4">
                <div className="cbw">
                  <div className="cbox">
                    <div className="ig01">
                      <img src={solution} alt="" />
                    </div>
                    <div className="tg01">
                      <div className="t01">솔루션</div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="sec01">
        <div className="business_wrap">
          <div className="wg01">
            <div className="w01">
              <div className="sec01tg01">
                <div className="t01">
                  DYNA SYSTEM
                  <br />
                  <span>Business</span>
                </div>
                <div className="t02">
                  다이나시스템은 시스템구축, IT시스템 진단, 설계, 구축
                  <br />
                  통합하여 고객의 비지니스 환경에 적합한 최적의
                  <br />
                  시스템과 네트워크 인프라 서비스를 제공합니다.
                </div>
              </div>
              <div className="ar_w01">
                <div className="swiper-button-prev swiper-button-prev01"></div>
                <div className="swiper-button-next swiper-button-next01"></div>
              </div>
            </div>
            <div className="w02">
              <div
                className="slide_type01"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-easing="ease-out-back"
                data-aos-duration="1000"
              >
                {/* Swiper 컴포넌트로 변경 */}
                <Swiper
                  modules={[Navigation, Autoplay]}
                  autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                  }}
                  slidesPerView="auto"
                  spaceBetween={14}
                  centeredSlides={false}
                  speed={500}
                  loop={true}
                  navigation={{
                    nextEl: ".swiper-button-next01",
                    prevEl: ".swiper-button-prev01",
                  }}
                  breakpoints={{
                    568: {
                      slidesPerView: "auto",
                      spaceBetween: 14,
                    },
                  }}
                  className="slider_item01"
                >
                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab1">
                        <span className="bg">
                          <img src={soga1} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">Consulting</strong>
                            <span className="sub-tit">최적의 정보시스템 구축 컨설팅</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>보안컨설팅</li>
                              <li>빅데이터 컨설팅</li>
                              <li>클라우드 컨설팅</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab2">
                        <span className="bg">
                          <img src={soga2} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">System integration</strong>
                            <span className="sub-tit">최적의 IT시스템을 진단, 설계, 통합</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>맞춤형 시스템 구축</li>
                              <li>빅데이터 / DW 구축</li>
                              <li>가상화 자원통합 구축</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab4">
                        <span className="bg">
                          <img src={soga3} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">Solution</strong>
                            <span className="sub-tit">업무시간을 단축하고 호율적인 솔루션</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>그룹웨어</li>
                              <li>관리시스템</li>
                              <li>통합검색 엔진</li>
                              <li>DB 암호화</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab3">
                        <span className="bg">
                          <img src={soga4} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">Outsourcing</strong>
                            <span className="sub-tit">고객 요구에 맞는 전문적인 서비스</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>시스템 관리, 운영 및 유지보수</li>
                              <li>금융기관 IT 아웃소싱</li>
                              <li>통합보안관제</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab1">
                        <span className="bg">
                          <img src={soga1} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">Consulting</strong>
                            <span className="sub-tit">최적의 정보시스템 구축 컨설팅</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>보안컨설팅</li>
                              <li>빅데이터 컨설팅</li>
                              <li>클라우드 컨설팅</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab2">
                        <span className="bg">
                          <img src={soga2} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">System integration</strong>
                            <span className="sub-tit">최적의 IT시스템을 진단, 설계, 통합</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>맞춤형 시스템 구축</li>
                              <li>빅데이터 / DW 구축</li>
                              <li>가상화 자원통합 구축</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab4">
                        <span className="bg">
                          <img src={soga3} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">Solution</strong>
                            <span className="sub-tit">업무시간을 단축하고 호율적인 솔루션</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>그룹웨어</li>
                              <li>관리시스템</li>
                              <li>통합검색 엔진</li>
                              <li>DB 암호화</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="item">
                      <a href="/field?tab=tab3">
                        <span className="bg">
                          <img src={soga4} alt="" className="img-cover" />
                        </span>
                        <div className="inner-box">
                          <div className="top">
                            <strong className="tit">Outsourcing</strong>
                            <span className="sub-tit">고객 요구에 맞는 전문적인 서비스</span>
                          </div>
                          <div className="bottom">
                            <ul className="detail-list">
                              <li>시스템 관리, 운영 및 유지보수</li>
                              <li>금융기관 IT 아웃소싱</li>
                              <li>통합보안관제</li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="sec02">
        <div className="container">
          <div className="solution_wrap">
            <div className="wg01">
              <div className="w01">
                <div className="sec02tg01">
                  <div className="t01">
                    DYNA SYSTEM
                    <br />
                    <span>Solution</span>
                  </div>
                  <div className="t02">
                    다이나시스템은 금융사업, 공공사업, 일반기업의
                    <br />
                    안전하고 호율적인 관리시스템을 제공합니다.
                  </div>
                </div>
              </div>

              <div className="w02">
                <div className="list-box">
                <div className="b-item">
                    <a href="/education">
                      <span className="item-tit">
                        <em className="num">03</em>
                        <strong className="title">
                          Education
                          <br />
                          Management System
                        </strong>
                        <p className="item-txt">
                          강좌, 시험, 과제 등<br className="pc_br" /> 교육관리를 쉽고 빠르게
                        </p>
                      </span>
                      <span className="icon-box">
                        <span>
                          <img src={sec02_3} alt="" />
                        </span>
                      </span>
                    </a>
                  </div>

                  <div className="b-item">
                    <a href="/distribution">
                      <span className="item-tit">
                        <em className="num">04</em>
                        <strong className="title">
                          Logistics
                          <br />
                          Management System
                        </strong>
                        <p className="item-txt">
                          호율적인 물류관리와
                          <br className="pc_br" />
                          고객 서비스 향상
                        </p>
                      </span>
                      <span className="icon-box">
                        <span>
                          <img src={sec02_4} alt="" />
                        </span>
                      </span>
                    </a>
                  </div>

                  <div className="b-item">
                    <a href="/blockchain">
                      <span className="item-tit">
                        <em className="num">05</em>
                        <strong className="title">Medical Blockchain</strong>
                        <p className="item-txt">
                          최첨단 의료 블록체인 기술로
                          <br className="pc_br" />
                          더 안전하고 효율적인 의료 환경을 제공
                        </p>
                      </span>
                      <span className="icon-box">
                        <span>
                          <img src={sec02_6} alt="" />
                        </span>
                      </span>
                    </a>
                  </div>

                  <div className="b-item">
                    <a href="/groupWare">
                      <span className="item-tit">
                        <em className="num">01</em>
                        <strong className="title">Groupware</strong>
                        <p className="item-txt">
                          다양한 업무 및 협업 환경에
                          <br className="pc_br" />
                          최적화된 프로그램
                        </p>
                      </span>
                      <span className="icon-box">
                        <span>
                          <img src={sec02_1} alt="" />
                        </span>
                      </span>
                    </a>
                  </div>

                  <div className="b-item">
                    <a href="/contents">
                      <span className="item-tit">
                        <em className="num">02</em>
                        <strong className="title">
                          Content
                          <br />
                          Management System
                        </strong>
                        <p className="item-txt">
                          HTML5, 웹표준, 웹접근성 100%준수
                          <br className="pc_br" />
                          PC, 테블릿, 모바일 등<br className="pc_br" />
                          반응형 웹 구현
                        </p>
                      </span>
                      <span className="icon-box">
                        <span>
                          <img src={sec02_2} alt="" />
                        </span>
                      </span>
                    </a>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="sec03">
        <div className="dynasystem">
          <div className="item slide_nav">
            <div className="inner">
              <div className="tit_wrap">
                <h2>
                  왜 <span>Dyna System</span> 인가?
                </h2>
                <p>
                  역동적, 원동력을 내재 하고 있는 개발자들이 오랜 기간 축적된 노하우와 탁월한 기술력으로
                  <br className="pc_only" />
                  <b>그룹웨어, 콘텐츠·교육·물류 관리시스템</b> 등 다양한 IT 인프라를 통합적으로 관리할 수 있는
                  <br className="pc_only" />
                  <b>안정적이고 호율적인 IT인프라 통합관리 솔루션</b>을 제공합니다.
                </p>
              </div>
              <div className="paging_wrap">
                <div className="inner swiper-pagination"></div>
              </div>
            </div>
          </div>
          <div className="item slide_wrap">
            <div className="slide slider_item02">
              <Swiper
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={14}
                centeredSlides={false}
                speed={500}
                loop={true}
                navigation={{
                  nextEl: ".slide_next",
                  prevEl: ".slide_prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true,
                  renderBullet: function (index, className) {
                    const bullets = ["물류관리시스템", "출고확정스캔", "고객퍼킹 리스트", "강의관리 페이지", "회원/강사관리 페이지"];
                    return `<div class="${className}">${bullets[index]}</div>`;
                  },
                }}
                breakpoints={{
                  568: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
                className="swiper-wrapper"
              >
                <SwiperSlide>
                  <strong>물류관리시스템</strong>
                  <div className="thumb_img">
                    <a href="">
                      <img src={monitor} alt="" />
                      <span className="inner">
                        <img src={sec03_1} alt="" />
                      </span>
                    </a>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <strong>출고확정스캔</strong>
                  <div className="thumb_img">
                    <a href="">
                      <img src={monitor} alt="" />
                      <span className="inner">
                        <img src={sec03_2} alt="" />
                      </span>
                    </a>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <strong>고객퍼킹 리스트</strong>
                  <div className="thumb_img">
                    <a href="">
                      <img src={monitor} alt="" />
                      <span className="inner">
                        <img src={sec03_3} alt="" />
                      </span>
                    </a>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <strong>강의관리 페이지</strong>
                  <div className="thumb_img">
                    <a href="">
                      <img src={monitor} alt="" />
                      <span className="inner">
                        <img src={sec03_4} alt="" />
                      </span>
                    </a>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <strong>회원/강사관리 페이지</strong>
                  <div className="thumb_img">
                    <a href="">
                      <img src={monitor} alt="" />
                      <span className="inner">
                        <img src={sec03_5} alt="" />
                      </span>
                    </a>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="slide_control">
                <button type="button" className="slide_prev"></button>
                <button type="button" className="slide_next"></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="sec04">
        <div className="container">
          <div className="msection_tit_wrap">
            <h2>Partnership</h2>
            <p>다이나 시스템의 안정성과 효율성을 검증받고 장기적인 신뢰 관계를 구축하고 있습니다.</p>
          </div>

          <div className="mcustomer">
            {/* Top Slider */}
            <div dir="rtl" className="slide_top">
              <Swiper
                modules={[Autoplay]}
                spaceBetween={100}
                centeredSlides={true}
                speed={6000}
                slidesPerView={5}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                loop={true}
                allowTouchMove={false}
                className="swiper-wrapper"
                slideToClickedSlide={false}
                watchSlidesProgress={true}
                grabCursor={false}
              >
                <SwiperSlide>
                  <img src={cbanner01} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner02} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner03} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner04} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner05} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner06} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner07} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner08} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner09} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={cbanner10} alt="바로가기" />
                </SwiperSlide>
              </Swiper>
            </div>

            {/* Bottom Slider */}
            <div className="slide_bottom">
              <Swiper
                modules={[Autoplay]}
                spaceBetween={100}
                centeredSlides={true}
                speed={6000}
                slidesPerView={5}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                loop={true}
                allowTouchMove={false}
                className="swiper-wrapper"
                slideToClickedSlide={false}
                watchSlidesProgress={true}
                grabCursor={false}
              >
                <SwiperSlide>
                  <img src={pbanner01} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner02} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner03} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner04} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner05} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner06} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner07} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner08} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner09} alt="바로가기" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pbanner10} alt="바로가기" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="more_btn">
            <a href="/partner">더보기</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
