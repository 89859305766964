import React from "react";
import left from "../../img/company/organization_pc2.png"
import right from "../../img/company/organization_pc2.png"

const Organization = () => {
  return (
    <div id="contents_in" className="organization">
      <div className="tit_area">
        <h3 className="title">회사조직</h3>
      </div>

      <div className="content01">
        <div className="container">
          <picture>
            <source srcSet={left} media="(max-width: 920px)" />
            <img src={right} alt="organization" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default Organization;
