import React from "react";
import contImg01 from "../../img/solution/cont_img01.jpg";
import contImg02 from "../../img/solution/cont_img02.jpg";
import contImg03 from "../../img/solution/cont_img03.jpg";
import contImg04 from "../../img/solution/cont_img04.jpg";
import contImg05 from "../../img/solution/cont_img05.jpg";
import contImg06 from "../../img/solution/cont_img06.jpg";
import contImg07 from "../../img/solution/cont_img07.jpg";
import check from "../../img/business/icon_check.png";
import contentImg01 from "../../img/solution/contents_img01.png";

// 섹션 타이틀 컴포넌트
const SectionTitle = ({ mainText, subText }) => (
  <div className="tit_area01">
    <h3 className="title01">
      <span>{mainText}</span> {subText}
    </h3>
    <div className="line"></div>
  </div>
);

// 메뉴 그룹 컴포넌트
const MenuGroup = ({ title, menuTop, items }) => (
  <div className="menu_group">
    <div className={`menu_top ${menuTop}`}>{title}</div>
    {items.map((item, index) => (
      <dl key={index}>
        <dt>{item.title}</dt>
        {item.subItems?.map((subItem, subIndex) => (
          <dd key={subIndex}>{subItem}</dd>
        ))}
      </dl>
    ))}
  </div>
);

// 특징 카드 컴포넌트
const FeatureCard = ({ imgSrc, title, points }) => (
  <div className="box_item">
    <div className="img_box">
      <img src={imgSrc} alt="" />
    </div>
    <div className="tg01">
      <div className="t01">{title}</div>
      <ul className="text-list">
        {points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
  </div>
);

const TechItem = ({ number, title, descriptions }) => (
  <div className="w01">
    <div className="titg">
      <div className="t01">
        <span>{number.padStart(2, "0")}</span>
        {title}
      </div>
      <div className="t02">
        {descriptions.map((desc, index) => (
          <p key={index}>{desc}</p>
        ))}
      </div>
    </div>
  </div>
);

// 제품개요 테이블 컴포넌트
const Table = ({ headers, rows }) => (
    <div className="table01 col">
      <table>
        <colgroup>
          <col style={{width: '30%'}}/>
          <col style={{width: '35%'}}/>
          <col style={{width: '35%'}}/>
        </colgroup>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} scope="col" className="bg_navi">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row.map((cell, cellIndex) => {
                // 기능형 APP인 경우 rowspan 추가
                if (index === 8 && cellIndex === 0) {
                  return <td key={cellIndex} rowSpan={7}>{cell}</td>;
                }
                // 기능형 APP의 하위 행들의 첫번째 셀은 건너뜀
                if (index > 8 && index <= 14 && cellIndex === 0) {
                  return null;
                }
                return (
                  <td key={cellIndex}>
                    {cell}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // 성능비교 테이블 컴포넌트
const Tables = ({ headers, rows }) => (
  <div className="table01 col">
    <table>
      <colgroup>
        {headers.map((_, index) => (
          <col key={index} style={{ width: `${100 / headers.length}%` }} />
        ))}
        
      </colgroup>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} scope="col" className={index === 1 ? "bg_blue" : ""}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {row.map((cell, cellIndex) => {
              return (
                <td key={cellIndex} className={cellIndex === 1 ? "bg_gray" : ""}>
                  {cell}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Contents = () => {
  // Why DYNASYSTEM CMS 데이터
  const whyDynasystem = [
    "Non-Active 환경",
    "웹 표준으로 개발되어 크로스브라우징 지원",
    "100% 자바 기반으로 개발되어 다양한 OS 플랫폼 지원",
    "모듈식 개발 방법 적용(표준 프레임워크 방식)",
    "표준 기술 적용을 통해 유지보수의 용이성, 시스템 확장성 보장",
  ];

  // 메뉴 구성도 데이터
  const menuStructure = {
    환경설정: {
      menuTop: "bg_navi",
      items: [
        {
          title: "기본설정",
          subItems: ["일반설정", "APIKEY 설정", "가입항목설정", "ANALYTICS"],
        },
        {
          title: "보안설정",
        },
        {
          title: "관리자 접근권한",
        },
      ],
    },
    콘텐츠관리자: {
      menuTop: "",
      items: [
        {
          title: "템플릿 관리",
          subItems: ["일반설정", "APIKEY 설정", "가입항목설정", "ANALYTICS"],
        },
        {
          title: "메뉴/콘텐츠 관리",
        },
        {
          title: "디자인 관리",
          subItems: ["레프트 메뉴", "탑 메뉴", "콘텐츠 담당자", "콘텐츠 수정버튼"],
        },
        {
          title: "웹표준/웹접근성 평가",
        },
        {
          title: "CSS관리",
        },
        {
          title: "Scripts 관리",
        },
        {
          title: "IMG 관리",
        },
        {
          title: "동영상 관리",
        },
        {
          title: "첨부파일 관리",
        },
      ],
    },
    프로그램개발자: {
      menuTop: "bg_navi",
      items: [
        {
          title: "사이트 관리",
          subItems: ["사이트 관리", "사이트 복사"],
        },
        {
          title: "회원패키지",
        },
      ],
    },
    통계: {
      menuTop: "",
      items: [
        {
          title: "ANALYTICS 통계",
          subItems: [
            "시간별 방문자",
            "브라우저별 방문자",
            "OS별 방문자",
            "일별 방문자 통계",
            "월별 방문자 통계",
            "년별 방문자 통계",
            "사이트별 통계",
            "인기도 통계",
          ],
        },
        {
          title: "관리자접속 로그관리",
        },
      ],
    },
    AP센터: {
      menuTop: "",
      items: [
        { title: "게시판 관리" },
        { title: "페이지 평가통계" },
        { title: "배너 관리" },
        { title: "알리미 관리" },
        { title: "팝업관리" },
        { title: "설문조사 시스템" },
        { title: "사이트맵 디자인" },
        { title: "통합검색 설정" },
        { title: "SMS관리" },
        { title: "EBOOK시스템" },
      ],
    },
  };

  // 주요 특장점 데이터
  const mainFeatures = [
    {
      imgSrc: contImg01,
      title: "화면처리방식은 객체지향형 MVC모델 방식을 통한 기본구조",
      points: [
        "모든 기능요소들을 ModelAndViewResolerController로 분리하여 기능요소들 자체가 퍼즐에 블록과 같이 재활용 및 불필요한 프로그램적 재개발을 최소화",
        "DispatcherServet 가 실제로 View에 리컨값을 전달하여 요청에 알맞게 응답을 생성",
        "HanderMapping 이 클라이언트가 요청한 url을 어떤 컨트롤러가 처리할지를 결정하여 세부적인 기능 컨트롤",
      ],
    },
    {
      imgSrc: contImg02,
      title: "국내최초!! 반응형웹 CMS 관리자 모드 제공",
      points: [
        "2012년 6월 전자정부프레임워크 2.1버전으로 JNTCMS 1.5버전 개발",
        "2012년 12월 전자정부프레임워크 2.1버전으로 JNTCMS 1.51버전 기능개선",
        "2013년 5월 전자정부프레임워크 2.5버전으로 JNTCMS 2.0버전 개발",
        "2013년 6월 관리자모드 반응협웨으로 화면개선 및 모듈디자인 표준화",
        "2015년 10월 전자정부프레임워크 3.5버전으로 호환성인증 획득",
      ],
    },
    {
      imgSrc: contImg03,
      title: "최대 확장형 전자정부프레임워크 기반 게시판 지원",
      points: [
        "자사에서 개발한 JNITCMS 게시판을 통해 29가지 유형에 게시판을 하나에 게시판 플랫폼으로 지원하였습니다.",
        "게시판이 가실 수 있는 기본에서부터 확장까지 최대로 고려하여 서비스 할 수 있는 게시판을 제공합니다.",
        "보통 메인페이지 불러오는 미니 게시판에 경우 직접 DB커넥팅이 아닌 HTML변환 모듈 탑재",
      ],
    },
    {
      imgSrc: contImg04,
      title: "국내최초!! 큐브리드 DBMS탑재!!",
      points: ["CUBRID 9.1.0.0212 버전 탑재 및 테스트 완료", "향후 추가되는 모든 기능들에 대해 CUBRID 동시 지원"],
    },
    {
      imgSrc: contImg05,
      title: "UTF-8 기반으로 제작하여 다국어 서비스를 지원",
      points: [
        "최초 자사의 JNTICMS는 글로벌 버전까지 겨냥하여 개발된 제품입니다. 이에 발맞추어 인코딩 방식을 국제표준인 UTF-8을 준용하여 개발됨",
        "향후 모든 기능들에 메시지는 7개국어 서비스될 예정입니다.",
      ],
    },
    {
      imgSrc: contImg06,
      title: "자사 단독지원 동영상 솔루션 지원 (별도 옵션)",
      points: [
        "일반적으로 판매되고 있는 동영상 솔루션 가격에 1/3가격으로 서비스 지원하고 있습니다.",
        "Uploder / 영상변환엔진 / web player 로 서비스가 구성되며, 모바일가지 완벽지원",
        "h264 Video Codec / AAc Audio Codec Support",
        "화면조절, 동영상 인코딩시 화면추출, 컨텐츠퍼가기 및 고객 커스터마이징 완벽지원",
      ],
    },
    {
      imgSrc: contImg07,
      title: "정부기관 관련 기능 최적화 (GPKI인증서 로그인, GPIN, 정부 통합센터 SMS모듈)",
      points: [
        "인증서 로그인에 경우 행정사이트에 경우 구축될 GPKI 모듈을 벌도 개발 없이 바로 서비스 가능",
        "Gpin에 경우 별도 개발 없이 행정사이트ㅇ 경우 바로 적용 및 서비스 가능",
        "행정사이트에 경우 정부통합센터에서 운영하는 SMS모듈 바로 적용 및 서비스 가능\n(서버 데몬방식으로 개발되어 안정성 보장)하나의 SMS시스템 형식으로 개발됨",
      ],
    },
  ];

  // 제품 개요 테이블 데이터
  const productOverview = {
    headers: ["항 목", "상 세", "비 고"],
    thClassName: "bg_navi",
    rows: [
      ["JDK", "JAVA SE 5.0 이상", ""],
      ["WAS", "Tomcat 6.0 이상", ""],
      ["Web Server", "Apache/2.2(Unix)", ""],
      ["IDE", "Elipse 3.6.2(Helios) 기반", ""],
      ["Framework", "전자정부표준 프레임워크", "Spring 기반"],
      ["Browser Support", "반응형 웹구현(Bootstrap적용)", "현행 모든 디바이스제공 (pc, 스마트폰, 태블릿pc,IP·TV등등)"],
      ["JavaScript Framework", "제한없음", "jQuery, ExtJS 등"],
      ["Database", "MY-SQL 5.1 이상 / 큐브리드", "오라클 10G 이상 지원 가능"],
      ["기능형 APP", "다음, 네이버 MAP API 기능 구현 및 로드뷰 자동구현 기능", "기본제공(찾아오시는길, 위치 정보표시 활용)"],
      ["", "다음 WEB-EDITOR 기본탑제", "기본제공"],
      ["", "콘텐츠페이지 평가 기능", "기본제공"],
      ["", "설문조사 기능 프로그램(문제풀이형까지 확장가능)", "기본제공"],
      ["", "페이지스크랩기능 프로그램", "기본제공"],
      ["", "실명인증, Gpin, 공인인증서 로그인방식 제공", "기본제공"],
      ["", "정부통합전산센터용 SMS 문자발송시스템 기능제공", "기본제공"],
    ],
  };

  // 성능 비교 테이블 데이터
  const comparisonData = {
    headers: ["비교 항목", "자사 CMS", "A사 CMS", "B사 CMS"],
    rows: [
      ["지원운영체제", "Windows, Unix, Linux", "Windows, Linux", "Linux"],
      ["RDB의 지원", "My-sql, Oracle, Cubrid(국내최초)", "My-sql, Oracle", "My-sql, Oracle"],
      ["Web 기반 UI", "모든 브라우저 지원", "모든 브라우저 지원", "익스플로러 지원안함"],
      ["콘텐츠별 필드 지정방식", "지원", "미지원", "지원"],
      ["정적/동적페이지 지원", "지원", "지원", "미지원"],
      ["다중 사이트 지원", "지원", "지원", "미지원"],
      ["다중 서버 배포지원", "지원", "미지원", "지원"],
      ["배포 스케쥴링 기능", "지원", "미지원", "미지원"],
      ["그룹/기능/폴더별 권한제어", "지원", "지원", "지원"],
      ["단위별 콘텐츠 변환", "지원", "미지원", "미지원"],
      ["승인 프로세스기능", "지원", "미지원", "미지원"],
      ["다중 승인체계(상황별)", "지원", "미지원", "지원"],
      ["전자정부프레임워크 연동", "지원(2.5최신버전)", "미지원", "지원(2.5최신버전)"],
      ["멀티디바이스 지원기능", "지원(국내최초 - 반응형으로 구축)", "미지원", "미지원"],
    ],
  };

  // 주요 기능 데이터
  const techFeatures = [
    {
      number: "01",
      title: "등급별 관리자",
      descriptions: ["통합관리자, 개발관리자, 사이트관리자, 등으로 구분하여 기능제공"],
    },
    {
      number: "02",
      title: "통합 관리자",
      descriptions: [
        "관리자 권한에서 서브 CMS 자동 구현",
        "회원관리 가능한 메뉴 기능 부여",
        "그룹별/사용자별/폴더별 및 콘텐츠별 접근권한 설정 가능",
        "웹사이트의 모든 콘텐츠의 권한을 통제, 부여가 가능, 권한은 직급 및 부서별로 하위 권한도 자동으로 가지게 함으로써 쉽게 관리권한 부여 및 이부 조직도 연계도 편리하게 구성",
        "조직개편에 따른 인사이동에 대처하기 위한 내부망 인사행정 시스템 및 전자결재 시스템 연동",
      ],
    },
    {
      number: "03",
      title: "개발 관리자",
      descriptions: [
        "개발자 관점에서 다양한 설정을 하고 데이터베이스 관리 및 관리자 메뉴, 회원유형 설정, 게시판 유형설정 및 파일 관리기능을 제공",
      ],
    },
    {
      number: "04",
      title: "사이트 관리자",
      descriptions: ["독립된 사이트 운영을 위한 데이터 및 콘텐츠 관리 기능 제공"],
    },
    {
      number: "05",
      title: "콘텐츠 및 게시판 등",
      descriptions: [
        "현업 담당자가 실시간으로 갱신할 수 있는 기능을 제공하고 버전관리로 언제든지 복구할 수 있도록 구성",
        "게시판 및 콘텐츠 실명확인 기능 적용",
        "인사이동이나 조직변경시 부서 홈페이지 및 관련 콘텐츠를 해당부서에서 처리할 수 있도록 구축하여 최신정보 제공",
        "게시판을 생성, 권한관리, 답변글, 꼬리댓글, 상단공지기능, 게시물개소, 승인작성, 용량제한, 공개(비공개)등 설정관리 기능 제공",
        "멀티(통합) 게시판 적용 및 연동",
        "배너를 쉽게 관리할 수 있는 기능제공",
        "팝업 팝업존 관리는 레이어 형태로 제공",
        "게시판별 배경색, 글자색 설정 가능",
        "기 도입한 웹필터, 불건전게시물 차단 프로그램 적용",
      ],
    },
    {
      number: "06",
      title: "사이트 / 배포 관리",
      descriptions: ["다중 사이트(도메인)관리 기능", "지금즉시/예약시점/주기적 배포 등 배포 시점의 스케줄링 기능 제공"],
    },
    {
      number: "07",
      title: "템플릿 관리",
      descriptions: [
        "템플릿 편집시 NotrPad, EditPlus, UltraEdit, DreamWeaver 및 각 사용자별 지정 애플리케이션과 연동한 편집 기능 제공",
        "정적 출판 방식과 동적 출판 방식을 모두 지원하며, 템플릿 코드 내용을 변경하지 않고도 출판 방식 변경이 가능하여야 한다.",
      ],
    },
    {
      number: "08",
      title: "통계 및 설문",
      descriptions: [
        "방문자 통계, 페이지별 만족도, 자주찾는 콘텐츠 각종 통계 분석 페이지를 구현 및 다중 설문(일반, 복수, 단답형) 조사기능 지원 등 홈페이지 운영현황 분석기능 구현",
        "지금즉시/예약시점/주기적 배포 등 배포 시점의 스케줄링 기능 제공",
      ],
    },
    {
      number: "09",
      title: "로그 관리",
      descriptions: ["각 방문자 통계, 시간, 일, 월, 요일, 브라우저 종류, OS 등 선택 시 방문자별 통계가 비율로 그래프를 통해 나타남"],
    },
    {
      number: "10",
      title: "기타",
      descriptions: [
        "CMS 자체도 웹표준화 및 웹접근성이 구현된 CMS 솔루션 기반 적용하여 관리자 페이지 접근 취약점 개선 및 보안강화의 모든 작업(배너관리, 팝업창, 웹문서 및 파일)은 웹표준 및 웹접근성 준수",
        "신기술을 활용한 사용자 접근 용이성 향상을 위한 맞춤서비스 제공",
        "홈페이지 내에 기능별 통합 검색이 가능하도록 개발",
        "WYSWYG 기능의 Visual HTML 에디터 기능을 지원",
        "기존 데이터 이전 및 변환 작업",
        "과업기간 중 변화사항을 지속적으로 반영하여 아키텍쳐 유지",
        "인터넷 시스템(H/W, S/W 보안시스템)등 안정적 관리",
        "관리 단계별 홈페이지 처리 프로세스에 맞춘 시스템 구현",
      ],
    },
  ];

  return (
    <div id="contents_in" className="solution contents">
      <div className="tit_area">
        <h3 className="title">콘텐츠관리시스템(CMS)</h3>
      </div>

      <div className="container">
        <div className="txt_box">
          HTML5, 웹표준, 웹접근성 100% 준수, 사이트 무한생성, 통합인증, 완벽한 개발 확장성을 보장하는 표준 기술을 개발된 CMS솔루션입니다.
          <br />
          또한 PC, 테블릿, 모바일 기기 등 반응형 웹을 지원하여 다양한 사용자 환경에 대응하는 유연한 제품입니다.
        </div>
      </div>

      <div className="content01">
        <div className="container">
          <div className="cont_box">
            <div className="tg01">
              <div className="t01">
                Why <span>DYNASYSTEM</span> CMS?
              </div>
              <div className="line"></div>
              <div className="t02">
                {whyDynasystem.map((item, index) => (
                  <React.Fragment key={index}>
                    <img src={check} alt="" />
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="fg01">
              <img src={contentImg01} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="content02">
        <div className="container">
          <SectionTitle mainText="제품" subText="개요" />
          <Table {...productOverview} />
        </div>
      </div>

      <div className="content03">
        <div className="container">
          <SectionTitle mainText="주요" subText="특장점" />
          <div className="box_list01">
            {mainFeatures.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </div>

      <div className="content04">
        <div className="container">
          <SectionTitle mainText="메뉴" subText="구성도" />
          <div className="menu_wrap">
            {Object.entries(menuStructure).map(([title, data], index) => (
              <MenuGroup key={index} title={title} {...data} />
            ))}
          </div>
        </div>
      </div>

      <div className="content05">
        <div className="container">
          <SectionTitle mainText="주요" subText="기능" />
        </div>
        <div className="tech_wrap">
          <div className="container">
            <div className="tech_list">
              <div className="tech_w" style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                {techFeatures.map((feature, index) => (
                  <TechItem key={index} number={feature.number} title={feature.title} descriptions={feature.descriptions} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content02">
        <div className="container">
          <SectionTitle mainText="성능" subText="비교" />
          <Tables {...comparisonData} />
        </div>
      </div>
    </div>
  );
};

export default Contents;
