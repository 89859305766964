import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const Nav2 = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
    <div id="lnb">
        <div className="lnb_w">	
            <ul >
            <li><a className={location.pathname === "/field" ? "on" : ""} onClick={()=>{navigate('/field')}} >사업분야</a></li>
            <li><a className={location.pathname === "/area" ? "on" : ""} onClick={()=>{navigate('/area')}} >사업영역</a></li>    
            <li><a className={location.pathname === "/partner" ? "on" : ""} onClick={()=>{navigate('/partner')}} >고객사/협력사</a></li>            
            </ul>
        </div>
    </div>
    );
};

export default Nav2;