import { useEffect, useState } from "react";
import gunmul from "../../img/company/cont_img01.jpg";

const Location = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    // 이미 스크립트가 로드되어 있는지 확인
    const existingScript = document.querySelector(".daum_roughmap_loader_script");

    if (!existingScript) {
      const script = document.createElement("script");
      script.charset = "UTF-8";
      script.className = "daum_roughmap_loader_script";
      script.src = "https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js";

      script.onload = () => {
        initializeKakaoMap();
      };

      document.body.appendChild(script);
    } else {
      initializeKakaoMap();
    }

    return () => {
      const script = document.querySelector(".daum_roughmap_loader_script");
      if (script) {
        script.remove();
      }
    };
  }, []);

  const initializeKakaoMap = () => {
      if (window.daum && window.daum.roughmap) {
        try {
          const mapContainer = document.getElementById("daumRoughmapContainer1728606134927");
          if (mapContainer && !mapContainer.innerHTML) {
            new window.daum.roughmap.Lander({
              timestamp: "1728606134927",
              key: "2kuvm",
            }).render();
          }
          setMapLoaded(true);
        } catch (error) {
          console.error("카카오맵 초기화 실패:", error);
        }
      }
  };

  return (
    <div id="contents_in" className="location">
      <div className="tit_area">
        <h3 className="title">오시는 길</h3>
      </div>

      {/* ************ 오시는 길 **************** */}
      <div className="content01">
        <div className="container">
          <div className="wg01">
            <div className="w01">
              <div className="picw01">
                <div className="pic01">
                  <img src={gunmul} alt="" />
                </div>
                <div className="tit_wg01">
                  <div className="tit_w01">Location of DYNAsystem</div>
                  <div className="tit_w02">Smart automation</div>
                </div>
              </div>
            </div>
            <div className="w02">
              <div className="txt-box">
                <h4 className="tit">본사</h4>
                <table className="location-table">
                  <colgroup>
                    <col style={{ width: "105px" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <p className="txt">주소</p>
                      </th>
                      <td>
                        <p className="txt font-score">서울시 영등포구 선유로49길 23 선유도역2차 IS비즈타워 7층 716호</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="txt">전화번호</p>
                      </th>
                      <td>
                        <p className="txt font-score">
                          <span>02-865-8684</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content02">
        <div className="container">
          <div className="w01">
            <div className="map_warp">
              <div
                id="daumRoughmapContainer1728606134927"
                className="root_daum_roughmap root_daum_roughmap_landing"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
