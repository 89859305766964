import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const Nav3 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    return (
    <div id="lnb">
        <div className="lnb_w">	
            <ul >
            <li><a className={location.pathname === "/groupWare" ? "on" : ""} onClick={()=>{navigate('/groupWare')}} >그룹웨어</a></li>
            <li><a className={location.pathname === "/contents" ? "on" : ""} onClick={()=>{navigate('/contents')}} >콘텐츠관리시스템</a></li>    
            <li><a className={location.pathname === "/education" ? "on" : ""} onClick={()=>{navigate('/education')}} >교육관리시스템</a></li>            
            <li><a className={location.pathname === "/distribution" ? "on" : ""} onClick={()=>{navigate('/distribution')}} >물류관리시스템</a></li>            
            <li><a className={location.pathname === "/blockchain" ? "on" : ""} onClick={()=>{navigate('/blockchain')}} >의료 블록체인</a></li>            
            </ul>
        </div>
    </div>
    );
};

export default Nav3;