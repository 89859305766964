import React from "react";
import blockchainGImg01 from "../../img/solution/blockchain_g_img01.jpg";
import blockchainGImg02 from "../../img/solution/blockchain_g_img02.jpg";
import blockchainGImg03 from "../../img/solution/blockchain_g_img03.jpg";
import blockchainGImg04 from "../../img/solution/blockchain_g_img04.jpg";
import blockchainIco01 from "../../img/solution/blockchain_ico01.png";
import blockchainIco02 from "../../img/solution/blockchain_ico02.png";
import blockchainIco03 from "../../img/solution/blockchain_ico03.png";
import icoSecurity from "../../img/solution/ico_security.png";
import check from "../../img/business/icon_check.png";
const features = [
    {
      id: 1,
      title: '강력한 보안',
      description: '블록체인의 분산형 구조를 통해 데이터 위·변조를 방지하고, 환자의 프라이버시를 철저히 보호합니다.',
      image: blockchainGImg01,
    },
    {
      id: 2,
      title: '데이터 접근성 향상',
      description: '의료 기관 간의 데이터 공유를 간소화하여 더 빠르고 정확한 진단 및 치료를 지원합니다.',
      image: blockchainGImg02,
    },
    {
      id: 3,
      title: '환자 중심의 데이터 소유권',
      description: '환자가 자신의 의료 정보를 직접 관리하고 필요한 곳에만 안전하게 제공할 수 있는 환경을 만듭니다.',
      image: blockchainGImg03,
    },
    {
      id: 4,
      title: '효율적인 비용 절감',
      description: '중복된 행정 업무와 데이터 오류를 줄임으로써 병원과 환자 모두에게 더 큰 가치를 제공합니다.',
      image: blockchainGImg04,
    },
  ];

  const conjugation = [
    {
      imgSrc: blockchainIco01,
      title: '전자 건강 기록(EHR) 관리',
      description: '병원 간 기록의 통합 관리로 환자 이력을 쉽고 안전하게 조회할 수 있습니다.',
    },
    {
      imgSrc: blockchainIco02,
      title: '의료 연구 데이터 공유',
      description: '암호화된 방식으로 연구 데이터를 공유하여 혁신적인 의학 연구를 촉진합니다.',
    },
    {
      imgSrc: blockchainIco03,
      title: '보험 청구 간소화',
      description: '보험 회사와의 신뢰성 있는 데이터 교환으로 청구 과정을 간편화합니다.',
    },
  ];
const BlockChain = () => {
    return (
    <div id="contents_in" className="solution blockchain">
      <div className="tit_area">
        <h3 className="title">의료 블록체인</h3>
      </div>

      <div className="cont_banner">
        <div className="container">
          <div className="title">
            최첨단 <strong>의료 블록체인 기술</strong>로<br />더욱 안전하고 효율적인 의료 환경을 제공합니다.
            <div>
              <img src={icoSecurity} alt="" />
              <p className="tit">데이터의 신뢰성, 보안, 그리고 접근성을 한 단계 끌어올리다.</p>
              <p className="txt">
                의료 정보 관리의 패러다임을 바꾸는 블록체인 기술, 환자의 개인 정보를 안전하게 보호하면서도
                <br />
                의료진과 연구기관이 필요한 데이터를 효율적으로 활용할 수 있도록 돕습니다.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-video">
          <video className="bg-video__content" autoPlay muted loop>
            <source src="/videos/blockchain_v01.mp4" type="video/mp4" />
            <source src="/videos/blockchain_v01.mp4" type="video/webm" />
            Sorry, your browser doesn&apos;t support embedded videos.
          </video>
        </div>
      </div>

      {/* 우리의 기술이 특별한 이유 */}
      <div className="container">
        <div className="tit_area01">
          <h3 className="title01">
            <span>우리의 기술</span>이 특별한 이유
          </h3>
          <div className="line"></div>
        </div>
      </div>

      <div className="content02">
        <div className="container">
          <ul className="txt_img_wrap">
            {features.map((feature, index) => (
              <li key={feature.id} className={index % 2 === 1 ? 'row02' : ''}>
                <div className="tg01" data-aos="fade-left">
                  <div className="tno">{`0${feature.id}`}</div>
                  <div className="t01">{feature.title}</div>
                  <div className="line"></div>
                  <div className="t02">
                    <img src={check} alt="" />
                    {feature.description}
                  </div>
                </div>
                <div className="fg01">
                  <img src={feature.image} alt={feature.title} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="content03">
        <div className="container">
          <div className="tit_area01">
            <h3 className="title01">
              <span>활용</span> 사례
            </h3>
            <div className="line"></div>
          </div>
          <div className="cbox_w01">
            <ul>
              {conjugation.map((conjugation, index) => (
                <li key={index}>
                  <div className="cb01">
                    <div className="tg01">
                      <figure>
                        <img src={conjugation.imgSrc} alt="" />
                      </figure>
                      <div className="t01">
                        <b>{conjugation.title}</b>
                      </div>
                      <div className="t02">{conjugation.description}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="content04">
        <div className="container">
          <div className="wg01">
            <div className="t_box">
              <div className="title">
                미래 의료를 위한 블록체인,
                <br />
                지금 시작하세요!
              </div>
              <div className="txt">
                안전하고 혁신적인 의료 블록체인 기술로 의료의 새로운 가능성을 열어보세요.
              </div>
            </div>
          </div>
          <div className="wg02">
            <div className="t_box">
              <div className="title">문의하기</div>
              <div className="txt">
                블록체인 기술을 활용한 의료 서비스에 대해 더 알고 싶다면 지금 바로 저희에게 연락하세요.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

export default BlockChain;