import React from 'react';
import check from "../../img/business/icon_check.png";
import distImg01 from "../../img/solution/dist_img01.png";
import distGImg01 from "../../img/solution/dist_g_img01.jpg";
import distGImg02 from "../../img/solution/dist_g_img02.jpg";
import distGImg03 from "../../img/solution/dist_g_img03.jpg";
import distSImg01 from "../../img/solution/dist_s_img01.png";
import slideMonitor from "../../img/slide_monitor.png";
import flowIco01 from "../../img/solution/flow_ico01.png";
import flowIco02 from "../../img/solution/flow_ico02.png";
import flowIco03 from "../../img/solution/flow_ico03.png";
import flowIco04 from "../../img/solution/flow_ico04.png";
import flowIco05 from "../../img/solution/flow_ico05.png";
import flowIco06 from "../../img/solution/flow_ico06.png";
import distWeb01 from "../../img/solution/dist_web01.jpg";
import distWeb02 from "../../img/solution/dist_web02.jpg";
import distWeb03 from "../../img/solution/dist_web03.jpg";
import distWeb04 from "../../img/solution/dist_web04.jpg";
import distWeb05 from "../../img/solution/dist_web05.jpg";
import distWeb06 from "../../img/solution/dist_web06.jpg";
import distWeb07 from "../../img/solution/dist_web07.jpg";
import distWeb08 from "../../img/solution/dist_web08.jpg";
import distWeb09 from "../../img/solution/dist_web09.jpg";
import distWeb10 from "../../img/solution/dist_web10.jpg";
import distWeb11 from "../../img/solution/dist_web11.jpg";
import distWeb12 from "../../img/solution/dist_web12.jpg";

const Distribution = () => {
    return (
        <div id="contents_in" className="solution dist">
        <div className="tit_area">
          <h3 className="title">물류관리시스템(LMS)</h3>
        </div>
  
        <div className="container">
          <div className="txt_box">
            물류관리 솔루션으로써, 전문물류의 업무환경 및 3PL(제 3자물류) 서비스를 전개하는 업체를 대상으로 개발된 솔루션입니다.
            물료센터 필수 프로세스가 탑재되어, 어느 물류업체에서도 쉽게 적용이 가능하도록 설계되었습니다. 적용 후 내부적으로는 물류관리능력,
            운영능력을 배가 시킬 수 있으며, 대외적으로 고객서비스 향상 및 만족도가 높아지며 업체신뢰도 향상에 영샹을 미치는 필수 관리 솔루션입니다.
          </div>
        </div>
  
        <div className="content01">
          <div className="container">
            <div className="cont_box">
              <div className="tg01">
                <div className="t01">
                  Why <span>DYNASYSTEM</span> LCMS?
                </div>
                <div className="line"></div>
                <div className="t02">
                  {[
                    "물류대기 기능을 통해 직배송건 분리, 예약 배송일 지정, 배송 전 재고파악 가능",
                    "물류관리에서 피킹, 패킹리스트를 출력하여 배송작업을 효율적으로 할 수 있으며, 연계 된 택배사의 경우 송장까지 자동 출력",
                    "상품의 상태별, 창고별, 시점별 상세 재고조회",
                    "택배 건 별 정산에 대해 확인 가능"
                  ].map((text, index) => (
                    <div key={index}>
                      <img src={check} alt="" />
                      {text}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
              <div className="fg01">
                <img src={distImg01} alt="" />
              </div>
            </div>
          </div>
        </div>
  
        <div className="container">
          <div className="tit_area01">
            <h3 className="title01">
              <span>LMS 도입</span> 효과
            </h3>
            <div className="line"></div>
          </div>
        </div>
  
        <div className="content02">
          <div className="container">
            <ul className="txt_img_wrap">
              {[
                {
                  no: "01",
                  title: "효율적 업무 운영",
                  points: [
                    "신속하고 정확한 물류업무 수행가능",
                    "로케이션 관리를 통한 공간 활용의 최적화",
                    "표준화를 통한 업무 능력 향상"
                  ],
                  img: distGImg01
                },
                {
                  no: "02",
                  title: "빠른 업무 관리",
                  points: [
                    "인터페이스를 통한 통합관리 체계 구축",
                    "실시간 시점 재고관리를 통한 재고정보 정확성 향상",
                    "물류센터 프로세스 전체과정에 대한 통합 모니터링"
                  ],
                  img: distGImg02
                },
                {
                  no: "03",
                  title: "계획적 경영관리",
                  points: [
                    "물류 생산성 향상을 통한 물류비 절감",
                    "리드타임(Leat Time) 감소",
                    "작업의 효율적 시간관리로 인한 인건비 절감"
                  ],
                  img: distGImg03
                }
              ].map((item, index) => (
                <li key={index} className={index === 1 ? "row02" : ""}>
                  <div className="tg01">
                    <div className="tno">{item.no}</div>
                    <div className="t01">{item.title}</div>
                    <div className="line"></div>
                    <div className="t02">
                      {item.points.map((point, i) => (
                        <div key={i}>
                          <img src={check} alt="" />
                          {point}
                          <br />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="fg01">
                    <img src={`${item.img}`} alt="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
  
        <div className="content03">
          <div className="container">
            <div className="tit_area01">
              <h3 className="title01">제품의 <span>특장점</span></h3>
              <div className="line"></div>
            </div>
  
            <div className="flow">
              {[
                { img: flowIco01, step: "01", title: "거래처&외주 입고 요청", desc: "입고장과 실물비교" },
                { img: flowIco02, step: "02", title: "입고 검사 내용 기록", desc: "거래처 별 입고 검사관리" },
                { img: flowIco03, step: "03", title: "재고 재포장 관리", desc: "실사/Location 이동관리" },
                { img: flowIco04, step: "04", title: "납품 오더 생성", desc: "출하 계획 수립&할당" },
                { img: flowIco05, step: "05", title: "재고 정보 취합", desc: "피킹&패킹 이력 관리" },
                { img: flowIco06, step: "06", title: "배송 정보 관리", desc: "출하 이력 관리" }
              ].map((item, index) => (
                <dl key={index} className={`${["one", "two", "three", "four", "five", "six"][index]}`}>
                  <dt>
                    <img src={`${item.img}`} alt="" />
                  </dt>
                  <dd>
                    STEP {item.step}
                    <p>{item.title}<br />{item.desc}</p>
                  </dd>
                </dl>
              ))}
            </div>
          </div>
        </div>
  
        {/* 시스템 흐름도 섹션 */}
        <div className="content04">
          <div className="container">
            <div className="tit_area01">
              <h3 className="title01"><span>시스템</span> 흐름도</h3>
              <div className="line"></div>
            </div>
            <div className="img_box01">
              <img src={distSImg01} alt="" />
            </div>
          </div>
        </div>
  
        {/* 주요화면 및 기능 섹션 */}
        <div className="content05">
          <div className="container">
            <div className="tit_area01">
              <h3 className="title01"><span>주요화면 및</span> 기능</h3>
              <div className="line"></div>
            </div>
  
            <div className="box_list01">
              {[
                {
                  title: "미처리 주문조회",
                  desc: [
                    "재고할당이 되어 지지 않은 상품에 대하여 부족분을 확인 할 수 있는 현황이다.",
                    "이 상품들에 대해서는 재입고가 되게 자동으로 제일먼저 재고 할당이 진행될 수 있도록 구성되어 있다."
                  ],
                  img: distWeb01
                },
                {
                  title: "출고 진행조회",
                  desc: ["실시간으로 현재 출고 진행사항을 조회하여 볼 수 있도록 구성 되어있다."],
                  img: distWeb02
                },
                {
                  title: "출고 진행조회",
                  desc: ["실시간으로 현재 출고 진행사항을 조회하여 볼 수 있도록 구성 되어있다."],
                  img: distWeb02
                },
                {
                  title: "출고확정스캔",
                  desc: [
                    "출고 시 운송장 또는 상품스캔을 통해 확정처리가 가능하여 보다 오차 없는 검수가 가능하다.",
                    "검수 진행 시 출고 쉬초 요청이 발생한 상품에 대해서는 출고가 나가지 못하도록 관리가 되어지고 있다."
                  ],
                  img: distWeb03
                },
                {
                  title: "출고송장분리",
                  desc: ["운송장 분리가 필요할 시 이곳에서 묶음된 송장을 분리하여 관리할 수 있다."],
                  img: distWeb04
                },
                {
                  title: "운송장 발행",
                  desc: ["7대 택배사가 연동 되어 있어 바로 송장 번호를 채번 하여 운송장 인쇄를 할 수 있도록 구성 되어있다."],
                  img: distWeb05
                },
                {
                  title: "고객 피킹 리스트",
                  desc: ["다양한 형태로 피킹 리스트를 출력할 수 있어 작업의 효율성을 극대화 할 수 있도록 구성 되어있다."],
                  img: distWeb06
                },
                {
                  title: "입고 업로드",
                  desc: [
                    "입고 시 유효일자를 등록할 수 있도록 되었다.",
                    "재고 할당 시 유효일자가 제일 적게 남은 순서로 재고 할당이 이루어 지게 되어 있다."
                  ],
                  img: distWeb07
                },
                {
                  title: "상품정보 업로드",
                  desc: [
                    "상품에 관련된 정보를 입력한다.",
                    "박스 입수량, 수량 표기시 재고 조회 현황에서 묶음 단위별 수량 표기가 가능해 진다.",
                    "유효 개월을 표기하면 재고 할당 시 유효 개월을 넘은 상품은 할당에서 자동으로 제외 되도록 구성되어있다."
                  ],
                  img: distWeb08
                },
                {
                  title: "로케이션 관리화면",
                  desc: ["창고와 작업자의 효율적 관리를 위해 로케이션을 관리 할수 있도록 한다."],
                  img: distWeb09
                },
                {
                  title: "의뢰 화면",
                  desc: ["화주가 직접 Excel 서식을 이용하여 자료를 업로드 하여 출고 의뢰 작업을 할 수 있다."],
                  img: distWeb10
                },
                {
                  title: "업무진행 현황",
                  desc: ["물류 작업 진행 현황을 시시간으로 모니터 할 수 있다."],
                  img: distWeb11
                },
                {
                  title: "업체별 청구화면",
                  desc: ["화주별 청구자료를 월별 일자 별로 상세히 확인해 볼 수 있다."],
                  img: distWeb12
                },
                // ... 나머지 항목들도 같은 형식으로 추가
              ].map((item, index) => (
                <div className="box_item" key={index}>
                  <div className="img_box">
                    <div className="thumb_img01">
                      <img src={slideMonitor} alt="" />
                      <span className="inner">
                        <img src={`${item.img}`} alt="" />
                      </span>
                    </div>
                  </div>
                  <div className="tg01">
                    <div className="t01">{item.title}</div>
                    <ul className="text-list">
                      {item.desc.map((text, i) => (
                        <li key={i}>{text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Distribution;