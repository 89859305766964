import React from "react";
import check from "../../img/business/icon_check.png";
import eduFImg01 from "../../img/solution/edu_f_img01.jpg";
import eduFImg02 from "../../img/solution/edu_f_img02.jpg";
import eduFImg03 from "../../img/solution/edu_f_img03.jpg";
import eduFImg04 from "../../img/solution/edu_f_img04.jpg";
import eduFImg05 from "../../img/solution/edu_f_img05.jpg";
import eduFImg06 from "../../img/solution/edu_f_img06.jpg";
import eduFImg07 from "../../img/solution/edu_f_img07.jpg";
import eduFImg08 from "../../img/solution/edu_f_img08.jpg";
import eduFImg09 from "../../img/solution/edu_f_img09.jpg";
import eduFImg10 from "../../img/solution/edu_f_img10.jpg";
import eduFImg11 from "../../img/solution/edu_f_img11.jpg";
import eduFImg12 from "../../img/solution/edu_f_img12.jpg";
import eduFImg13 from "../../img/solution/edu_f_img13.jpg";
import eduFImg14 from "../../img/solution/edu_f_img14.jpg";
import eduFImg15 from "../../img/solution/edu_f_img15.jpg";
import eduGImg01 from "../../img/solution/edu_g_img01.jpg";
import eduGImg02 from "../../img/solution/edu_g_img02.jpg";
import eduGImg03 from "../../img/solution/edu_g_img03.jpg";
import eduWeb01 from "../../img/solution/edu_web01.png";
import eduWeb02 from "../../img/solution/edu_web02.png";
import eduWeb03 from "../../img/solution/edu_web03.png";
import eduWeb04 from "../../img/solution/edu_web04.png";
import eduWeb05 from "../../img/solution/edu_web05.png";
import eduImg01 from "../../img/solution/edu_img01.png";
import slideMonitor from "../../img/slide_monitor.png";

// 공통 컴포넌트들
const SectionTitle = ({ mainText, subText, description }) => (
  <div className="tit_area01">
    <h3 className="title01">
      <span>{mainText}</span> {subText}
    </h3>
    <div className="line"></div>
    {description && <p>{description}</p>}
  </div>
);

const FeatureCard = ({ imgSrc, title, subtitle, points }) => (
  <div className="box_item">
    <div className="img_box">
      <img src={imgSrc} alt="" />
    </div>
    <div className="tg01">
      <div className="t01">
        {title}
        {subtitle && <p>{subtitle}</p>}
      </div>
      <ul className="text-list">
        {points.map((point, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: point }} />
        ))}
      </ul>
    </div>
  </div>
);

const MenuGroup = ({ title, menuTop, items }) => (
  <div className="menu_group">
    <div className={`menu_top ${menuTop}`}>{title}</div>
    {items.map((item, index) => (
      <dl key={index}>
        {item.title && <dt>{item.title}</dt>}
        {item.subItems?.map((subItem, subIndex) => (
          <dd key={subIndex}>{subItem}</dd>
        ))}
      </dl>
    ))}
  </div>
);

const Education = () => {
  // Why DYNASYSTEM LCMS 데이터
  const whyDynasystem = [
    "회원 분류와 그룹 기능을 이용하여 체계적이고 간편하게 회원을 관리",
    "동영상 플랫폼과 연동하여 보유하신 콘텐츠를 손쉽게 강의로 등록",
    "수강 대상, 강의 교재, 복습 관리, 평가 정보 등 과정 세부정보를 원하시는 대로 설정하여 다양한 유형의 과정을 효과적으로 운영",
  ];

  // 주요 특장점 데이터
  const features = [
    {
      imgSrc: eduFImg01,
      title: "강좌, 콘텐츠 관리",
      subtitle: "빠르고 직관적인 강좌등록",
      points: [
        "기존 솔루션의 강좌등록과정은 복잡합니다.<br>하지만 자사 LCMS는 '강좌 분류 > 강좌 개설 > 콘텐츠 등록' 3단계를 통해 강좌를 생성할 수 있습니다.<br>강좌의 복사기능과 콘텐츠의 복사, 수정, 삭제 역시 한번에 가능합니다.",
      ],
    },
    {
      imgSrc: eduFImg02,
      title: "강좌 진열 순서",
      subtitle: "자유로운 드래그앤드롭 수정",
      points: [
        "강좌 및 상품의 진열 순서를 마우스 드래그로 자유롭게 변경할 수 있습니다.",
        "신규강좌나 신상품을 생성하였을 때 간편하게 진열 순서를 변경하세요.<br>진열 순서는 매울에 영향을 주는 중요한 사항입니다.",
      ],
    },
    {
      imgSrc: eduFImg03,
      title: "무료강좌",
      subtitle: "권한 설정이 자유로운 무료강좌!",
      points: ["관리자의 승인이 필요한 무료강좌와 관리자의 승인 없이 바로 수강가능한 무료강좌 중 선택하여 자유롭게 생성이 가능합니다."],
    },
    {
      imgSrc: eduFImg04,
      title: "콘텐츠 보안",
      subtitle: "캡쳐 보안, URL보안, 서버 보안",
      points: [
        "자사 LCMS 솔루션은 강력한 동영상 플에이어를 탑재하였습니다.",
        "플레이어는 동영상의 보안기능, 배속기능과 함께 웹 방식의 통합서버관리/모니터링 기능을 제공하여 콘텐츠의 유출을 근복적으로 방어합니다.",
      ],
    },
    {
      imgSrc: eduFImg05,
      title: "워터마크 정책",
      subtitle: "캠코더 보안",
      points: [
        "캠코더, 디지털카메라 등을 통하여 모니터를 녹화하는 경우 콘텐츠 유출을 방어하기 위하여 워터마크 정책을 설정할 수 있습니다.",
        "수강화면에 위치, 글자 크기, 글자 색상 등을 지정하여 회원 ID, 회원명등 노출시킬 수 있습니다.",
      ],
    },
    {
      imgSrc: eduFImg06,
      title: "플레이어 (유튜브 연동 가능)",
      subtitle: "강의를 보면서 질문, 자료실, 북마크, 메모를?",
      points: [
        "플레이어창에서 다양한 기능을 이용하세요.",
        "학습 중 질문을 남기거나 자료 다운로드, 북마크, 메모 등 강의를 수강하여 필요한 내용을 동시에 이용하실 수 있습니다.(크로스 브라우징 지원)",
      ],
    },
    {
      imgSrc: eduFImg07,
      title: "수강 관리",
      subtitle: "두 가지의 카테고리로 손 쉬운 관리",
      points: [
        "회원별 수강 관리 및 강좌별 수강 관리를 통해 수강이력, 수강기기, 일시정지 이력 등을 확인할 수 있습니다.",
        "관리자가 회원의 수강기간을 직접 변경할 수 있으며, 변경내역은 모두 기록됩니다. 정확한 이력으로 고객을 응대하세요.",
      ],
    },
    {
      imgSrc: eduFImg08,
      title: "일시정지",
      subtitle: "관리자, 사용자의 만족 100% 수강 일시정지!",
      points: [
        "회원별 수강 관리 및 강좌별 수강 관리를 통해 수강이력, 수강기기, 일시정지 이력 등을 확인할 수 있습니다.",
        "관리자가 회원의 수강기간을 직접 변경할 수 있으며, 변경내역은 모두 기록됩니다. 정확한 이력으로 고객을 응대하세요.",
      ],
    },
    {
      imgSrc: eduFImg09,
      title: "문제은행",
      subtitle: "문제를 만들어 저장하고 시험출제까지!",
      points: [
        "문제를 생성하여 퀴즈와 시험에서 출제를 할수 있습니다.",
        "퀴즈와 시험의 난이도는 무제 생성시 입력한 난이도의 평균이 자동으로 반영됩니다.",
      ],
    },
    {
      imgSrc: eduFImg10,
      title: "과제관리",
      subtitle: "수강생에게 과제를 출재하고 체점까지!",
      points: [
        "강좌를 듣는 수강생들에게 과제를 출제할 수 있습니다.",
        "제출된 과제에 대해 선생님(관리자)이 첨삭 또는 채점을 할 수 있습니다.",
      ],
    },
    {
      imgSrc: eduFImg11,
      title: "게시판 관리",
      subtitle: "여러 게시판을 한 방에 관리",
      points: ["게시판을 개수에 제한없이 생성할 수 있습니다. 또한 회원권한, 게시판 스킨 등을 일괄설정으로 쉽게 설정할 수 있습니다."],
    },
    {
      imgSrc: eduFImg12,
      title: "로그분석",
      subtitle: "성공적인 이러닝사이트 운영을 위한 필수!",
      points: [
        "사이트 유입부터 이탈까지 방문자의 모든 행동을 분석합니다.",
        "실시간으로 방문자의 활동을 분석하여 사이트 최적화에 유용합니다.",
        "성공적인 이러닝 사이트 운영을 위한다면, 꼭 필요한 로그분석 바로 시작하세요!",
      ],
    },
    {
      imgSrc: eduFImg13,
      title: "회원관리",
      subtitle: "효율성을 높여주는 회원관리",
      points: [
        "회원을 그룹별로 분류하여 효율적으로 관리할 수 있습니다.",
        "수강상태, 방문일, 구매금액 등 다양한 조건으로 회원을 검색할 수 있습니다.",
        "회원의 상담내역 및 특이사항을 입력하여 이력으로 남길 수 있습니다.",
        "회원가입시 입력 항목을 자유롭게 설정할 수 있습니다.",
      ],
    },
    {
      imgSrc: eduFImg14,
      title: "관리자 설정",
      subtitle: "선생님만을 위한 관리자페이지",
      points: [
        "최고관리자가 부관리자(운영자)를 추가할 수 있습니다.",
        "아이디와 비밀번호를 입력하여 부관리자 계정을 설정하고, 관리메뉴에 대한 권한을 부여합니다.",
        "포탈형 이러닝 사이트를 운영하는 고객님을 위해 각 선생님만의 관리자 페이지를 지원합니다.",
        "선생님은 자신의 강좌 개설, 수정, 자료관리, 게시물관리, 수강생관리 등이 가능합니다.",
      ],
    },
    {
      imgSrc: eduFImg15,
      title: "모바일",
      subtitle: "스마트 기기에 대응하는 모바일 서비스 지원",
      points: [
        "지금은 모바일 온리 시대!<br>모바일 사용이 부담스러운 어르신부터 모든 것을 모바일로만 하는 젊은 계층까지, 최근 사용자 환경을 고려한 고객에 맞는 모바일 서비스를 제공하세요.",
      ],
    },
  ];

  // 메뉴 구조 데이터
  const menuGroups = [
    {
      title: "환경설정",
      menuTop: "bg_navi",
      items: [
        {
          title: "기본설정",
          subItems: ["사이트 환경설정", "결제설정", "매니저설정", "SMS설정"],
        },
        { title: "개인정보변경" },
        { title: "이용약관" },
      ],
    },
    {
      title: "디자인 관리",
      menuTop: "",
      items: [
        {
          title: "기본디자인 설정",
          subItems: ["로고 및 카피라이터", "메인비쥬얼 배너관리", "고객센터 배너관리", "메인하단 배너관리"],
        },
        {
          title: "서브페이지 디자인",
          subItems: ["교육원소개", "학습지원센터"],
        },
        { title: "팝업창관리" },
      ],
    },
    {
      title: "회원정보관리",
      menuTop: "",
      items: [
        {
          title: "회원관리",
          subItems: ["회원관리", "상담관리"],
        },
        { title: "포인트 관리" },
      ],
    },
    {
      title: "강사관리",
      menuTop: "bg_navi",
      items: [
        {
          title: "강사분류 관리",
          subItems: ["강사관리"],
        },
      ],
    },
    {
      title: "강의 관리",
      menuTop: "",
      items: [
        {
          subItems: ["강의분류 관리", "강의관리", "시험관리"],
        },
      ],
    },
    {
      title: "수강관리",
      menuTop: "",
      items: [
        {
          title: "주문관리",
          subItems: ["결제관리", "환불관리", "배송관리"],
        },
        {
          title: "수강관리",
          subItems: ["수강관리", "수료관리", "교육기관관리"],
        },
      ],
    },
    {
      title: "고객센터",
      menuTop: "",
      items: [{ title: "공지사항" }, { title: "자주하는질문" }, { title: "문의사항" }, { title: "게시판관리" }],
    },
    {
      title: "통계현황",
      menuTop: "",
      items: [
        { title: "매출통계" },
        { title: "사이트 방문자 통계" },
        { title: "수강/강의 통계" },
        { title: "회원 통계" },
        { title: "강사 통계" },
      ],
    },
  ];

  // 도입효과 데이터
  const effects = [
    {
      number: "01",
      title: "비용 절감",
      points: [
        "오프라인 수업 운영으로 인한 강의실 대관비용 및 강사 섭외 비용이 절감됩니다.",
        "수작업에 따른 시간 비용(인건비) 및 문서 비용이 절감됩니다.",
      ],
      imgSrc: eduGImg01,
    },
    {
      number: "02",
      title: "관리생산성 향상",
      points: [
        "단순 취합 작업에 걸리는 시간을 보다 생산적인 활동에 투자가 가능합니다.",
        "관련 직원의 업무 만족도 증가 및 업무 수준이 향상됩니다.",
        "오프라인 교육의 수작업을 전산화하여 관리가 용이합니다.",
      ],
      imgSrc: eduGImg02,
    },
    {
      number: "03",
      title: "비용 절감",
      points: [
        "회원들의 시간/공간 제약을 없엔 간편한 교육 방식을 통해 적은 비용으로 높은 교육 효과를 제공합니다.",
        "교육의 편의 제공을 통해 회원들의 교육 참여율이 향상됩니다.",
      ],
      imgSrc: eduGImg03,
    },
  ];

  // 관리자 화면 예시 데이터
  const adminScreens = [
    {
      title: "환경설정 페이지",
      imgSrc: eduWeb02,
    },
    {
      title: "디자인관리 페이지",
      imgSrc: eduWeb03,
    },
    {
      title: "강의관리 페이지",
      imgSrc: eduWeb04,
    },
    {
      title: "회원/강사관리 페이지",
      imgSrc: eduWeb05,
    },
  ];

  return (
    <div id="contents_in" className="solution edu">
      {/* 제목 영역 */}
      <div className="tit_area">
        <h3 className="title">교육관리시스템(LCMS)</h3>
      </div>

      {/* 소개 텍스트 */}
      <div className="container">
        <div className="txt_box">
          회원가입에서부터 과정개설, 학습운영 및 매출관리에 이르기까지 학습자의 원활한 학습진행과 운영자의 편리한 관리를 위한 효율적인
          환경을 제공합니다. 관리자프로그램을 통해 이루어지는 다양하고 편리한 관리기능으로 최적의 운영을 지원하여 전문적이고 편리한
          교육관리기능을 제공합니다.
        </div>
      </div>

      {/* Why DYNASYSTEM LCMS */}
      <div className="content01">
        <div className="container">
          <div className="cont_box">
            <div className="tg01">
              <div className="t01">
                Why <span>DYNASYSTEM</span> LCMS?
              </div>
              <div className="line"></div>
              <div className="t02">
                {whyDynasystem.map((item, index) => (
                  <React.Fragment key={index}>
                    <img src={check} alt="" />
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="fg01">
              <img src={eduImg01} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* 주요 특장점 */}
      <div className="content03">
        <div className="container">
          <SectionTitle mainText="주요" subText="특장점" description="강좌, 시험, 과제 등 교육관리를 쉽고 빠르게" />
          <div className="box_list01">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </div>

      {/* 세부 기능 목록 */}
      <div className="content04">
        <div className="container">
          <SectionTitle mainText="세부 기능" subText="목록" />
          <div className="menu_wrap col-4">
            {menuGroups.map((group, index) => (
              <MenuGroup key={index} {...group} />
            ))}
          </div>
        </div>
      </div>

      {/* LCMS 도입 효과 */}
      <div className="container">
        <SectionTitle mainText="LCMS 도입" subText="효과" />
      </div>
      <div className="content05">
        <div className="container">
          <ul className="txt_img_wrap">
            {effects.map((effect, index) => (
              <li key={index} className={index === 1 ? "row02" : ""}>
                <div className="tg01">
                  <div className="tno">{effect.number}</div>
                  <div className="t01">{effect.title}</div>
                  <div className="line"></div>
                  <div className="t02">
                    {effect.points.map((point, pIndex) => (
                      <React.Fragment key={pIndex}>
                        <img src={check} alt="" />
                        {point}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="fg01">
                  <img src={effect.imgSrc} alt="" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* 화면 예시 - 사용자 화면 */}
      <div className="content06">
        <div className="container">
          <SectionTitle mainText="사용자 화면" subText="예시" />
          <div className="product01">
            <div className="thumb_img01">
              <img src={slideMonitor} alt="" />
              <span className="inner">
                <img src={eduWeb01} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* 화면 예시 - 관리자 화면 */}
      <div className="content06">
        <div className="container">
          <SectionTitle mainText="관리자 화면" subText="예시" />
          <div className="product01">
            {adminScreens.map((screen, index) => (
              <div key={index} className="w01">
                <h2>{screen.title}</h2>
                <div className="thumb_img01">
                  <img src={slideMonitor} alt="" />
                  <span className="inner">
                    <img src={screen.imgSrc} alt="" />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
