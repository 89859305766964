import React, { useState, useEffect } from "react";
import historyLogo from "../../img/company/history_bg01.jpg";
import { useDisHistoryList } from "hooks/services/companyInfo";

const History = () => {
  //=============================================================================================================================================================================
  // useState
  //=============================================================================================================================================================================
  const [dataList, setDataList] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 회사연혁 조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disHistoryList } = useDisHistoryList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setActiveTab(data.result[0].year);
        setDataList(data.result);
      } else {
      }
    },
  });

  useEffect(() => {
    disHistoryList([null, {}]);

    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
      if (window.scrollY > 200) {
        const hisTap = document.querySelector(".his_tap");
        if (hisTap) hisTap.style.display = "block";
      } else {
        const hisTap = document.querySelector(".his_tap");
        if (hisTap) hisTap.style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // 탭 클릭 핸들러
  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);

    // 해당 년도의 요소를 찾아서 스크롤
    const element = document.getElementById(tab);
    if (element) {
      // 헤더의 높이를 고려한 오프셋 설정 (예: 헤더 높이가 80px인 경우)
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div id="contents_in" className="history">
      <div className="tit_area">
        <h3 className="title">회사연혁</h3>
      </div>

      <div className="his_tap">
        <ul>
          {dataList?.map((item) => {
            return (
              <li key={item.year}>
                <a
                  href={"#" + item.year}
                  className={activeTab === item.year ? "on" : ""}
                  data-tab={item.year}
                  onClick={(e) => handleTabClick(e, item.year)}
                >
                  {item.year}년
                </a>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="content01">
        <div className="container">
          <div className="bn_wg01 history">
            <div className="pic_w01">
              <img src={historyLogo} alt="" />
            </div>
            <div className="tit_w01">History of DYNAsystem</div>
            <div className="tit_w02">Smart automation</div>
          </div>
        </div>
      </div>

      <div className="content02">
        <div className="container">
          <div className="tableWrap">
            {dataList?.map((item) => {
              return (
                <div className="his_in" key={item.year} id={item.year}>
                  <div className="his_box his_txt">
                    <h6>{item.year}</h6>
                    {item.comment.map((comment, index) => {
                      return (
                        <div className="his_list" key={index}>
                          {comment}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
