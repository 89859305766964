import React from "react";
import leader from "../../img/company/ph_ico02.png";
import ddabong from "../../img/company/ph_ico01.png";
import upman from "../../img/company/ph_ico06.png";
import shakeCheck from "../../img/company/ph_ico07.png";
import worldClassMen from "../../img/company/vi_img02.jpg";
import human_light from "../../img/company/ph_ico04.png";
import light from "../../img/company/ph_ico03.png";
import fire from "../../img/company/ph_ico05.png";
import bigUp from "../../img/company/vi_img01.jpg";
import bottom1 from "../../img/company/vita_img01.jpg";
import bottom2 from "../../img/company/vita_img02.jpg";
import bottom3 from "../../img/company/vita_img03.jpg";
import bottom4 from "../../img/company/vita_img04.jpg";

const Philosophy = () => {
  return (
    <div id="contents_in" className="philosophy">
      <div className="tit_area">
        <h3 className="title">기업이념</h3>
      </div>

      {/* 기업이념,경영방침 */}
      <div className="content01">
        <div className="container">
          <div className="wg01">
            <h3 className="t01">
              기업이념 <span>With you !</span>
            </h3>
            <div className="box-slogan">
              <span>
                항상 고객과 함께하는 따뜻한 <b>ICT 전문기업</b> With you !
              </span>
            </div>

            <h3 className="t01">
              경영방침 <span>한발 앞선 경영</span>
            </h3>
            <div className="box-slogan">
              <span>
                빠른 의사결졍과 강력한 실행력을 통해 '한발 앞선 경영'으로 '창의적이고 역동적인 고객 중심의 ICT 전문기업'
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* 행동지침,핵심가치 */}
      <div className="content02">
        <div className="container">
          <div className="wg01">
            <div className="titg01">
              <h3 className="t01">행동지침</h3>
            </div>
            <ul className="circle_wg">
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={ddabong} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">당당하게</div>
                        <div className="t02">
                          매사에 할 수 있다는
                          <br />
                          자신감과 전문지식을
                          <br />
                          가지고 "당당하게"
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={leader} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">내가 먼저</div>
                        <div className="t02">
                          궂은 일은 솔선수범하며
                          <br />
                          적극적인 자세로
                          <br />
                          "내가 먼저"
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={light} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">우리 함께</div>
                        <div className="t02">
                          힘든 일은 서로 돕고
                          <br />
                          모두 힘을 모아
                          <br />
                          "우리함께"
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="cont_line01"></div>
          <div className="wg01">
            <div className="titg01">
              <h3 className="t01">핵심가치</h3>
              <h4 className="t02">"고객 중심의 가치창조"</h4>
            </div>
            <ul className="circle_wg">
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={human_light} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">창의</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={fire} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">열정</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={upman} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">도전</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="">
                  <div className="cbw">
                    <div className="cbox">
                      <div className="ig01">
                        <img src={shakeCheck} alt="" />
                      </div>
                      <div className="tg01">
                        <div className="t01">신뢰</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="txt">
              <li>
                ◎ 새로운 가치를 향한 <b>'창의'</b> / 목표에 몰입하는 <b>'열정'</b> / 최고를 향한 <b>'도전'</b>
              </li>
              <li>
                ◎ 고객과 동료와의 '신뢰'를 바탕으로 고객 중심으로 생각하고 행동하며 가치를 창조하고 나누는{" "}
                <b>다이나시스템</b>
              </li>
            </ul>
          </div>
          <div className="cont_line01"></div>
        </div>
      </div>

      {/* 비전 */}
      <div className="content03">
        <div className="container">
          <div className="wg01">
            <div className="w01">
              <img src={bigUp} alt="" />
            </div>
            <div className="w02">
              <div className="tg01">
                <div className="t01">비전</div>
                <div className="t02">DynaSystem Business</div>
                <div className="t03">
                  다이나시스템(주)은 강력한 성장 INFRA를 기반으로 기존사업부분을 더욱 진화시키고, 신성장 동력을 지속
                  발굴하여 2027년 매출 100억원을 달성하고, 고객에게 만족을 넘어 감동을 전하는 따뜻한 ICT 전문 기업이
                  되고자 노력하고 있습니다.
                </div>
              </div>
            </div>
          </div>
          <div className="wg01 row02">
            <div className="w01">
              <img src={worldClassMen} alt="" />
            </div>
            <div className="w02">
              <div className="tg01">
                <div className="t02">고객에게 감동을 전하는 따뜻한 ICT 전문기업</div>
                <div className="t03">
                  다이나시스템은 SI/SM, 솔루션 사업 분야의 성장 인프라를 기반으로 교육, 물류, 빅데이터, 클라우드,
                  정보보안 등 전문 컨설팅 분야와 콘텐츠, 솔루션 개발 등 다양한 ICT분야를 지속 발굴하여 고객 만족을
                  뛰어넘어 고객 감동을 전하고 그 가치를 함께 나누는 (WITH YOU) '따뜻한 ICT 전문 기업이 되고자 노력하고
                  있습니다.'
                </div>
              </div>
            </div>
          </div>
          <div className="cont_line01"></div>
        </div>
      </div>

      {/* 비전 달성 전략 */}
      <div className="content04">
        <div className="container">
          <div className="titg01">
            <h3 className="t01">비전 달성 천략</h3>
          </div>
          <ul className="list_wg">
            <li>
              <div className="wg01">
                <div className="w01">
                  <img src={bottom1} alt="" />
                </div>
                <div className="w02">
                  <div className="tg01">
                    <div className="t01">기존사업 진화</div>
                    <div className="t02">
                      유통채녈 다변화 / 전국권 사업기반 구축
                      <br />
                      마케팅 역량 강화 / 솔루션 고도화
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="wg01">
                <div className="w01">
                  <img src={bottom2} alt="" />
                </div>
                <div className="w02">
                  <div className="tg01">
                    <div className="t01">성장 INFRA 구축</div>
                    <div className="t02">
                      핵심인재 / 리더양성 / 고객관리 / 서비스 중심
                      <br />
                      책임경영체제 정착 / 훌륭한 일터 만들기
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="wg01">
                <div className="w01">
                  <img src={bottom3} alt="" />
                </div>
                <div className="w02">
                  <div className="tg01">
                    <div className="t01">신성장 동력 발굴</div>
                    <div className="t02">
                      신규수익 기반 지속개발 / 신규사업 전담조직 운용
                      <br />
                      신규사업 추친 역량 강화 / 해외 사업 진출
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="wg01">
                <div className="w01">
                  <img src={bottom4} alt="" />
                </div>
                <div className="w02">
                  <div className="tg01">
                    <div className="t01">사회 공헌하는 기업</div>
                    <div className="t02">
                      연말불우이웃 돕기 / NGO 단체 지원
                      <br />
                      찾아가는 지원봉사 실천 / 지속적인 기부금 전달
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Philosophy;
