import React from 'react';

//  private banner imports
import p1 from '../../img/banner/p_banner01.jpg';
import p2 from '../../img/banner/p_banner02.jpg';
import p3 from '../../img/banner/p_banner03.jpg';
import p4 from '../../img/banner/p_banner04.jpg';
import p5 from '../../img/banner/p_banner05.jpg';
import p6 from '../../img/banner/p_banner06.jpg';
import p7 from '../../img/banner/p_banner07.jpg';
import p8 from '../../img/banner/p_banner08.jpg';
import p9 from '../../img/banner/p_banner09.jpg';
import p10 from '../../img/banner/p_banner10.jpg';
import p11 from '../../img/banner/p_banner11.jpg';
import p12 from '../../img/banner/p_banner12.jpg';

//  public banner imports
import c1 from '../../img/banner/c_banner01.jpg';
import c2 from '../../img/banner/c_banner02.jpg';
import c3 from '../../img/banner/c_banner03.jpg';
import c4 from '../../img/banner/c_banner04.jpg';
import c5 from '../../img/banner/c_banner05.jpg';
import c6 from '../../img/banner/c_banner06.jpg';
import c7 from '../../img/banner/c_banner07.jpg';
import c8 from '../../img/banner/c_banner08.jpg';
import c9 from '../../img/banner/c_banner09.jpg';
import c10 from '../../img/banner/c_banner10.jpg';
import c11 from '../../img/banner/c_banner11.jpg';
import c12 from '../../img/banner/c_banner12.jpg';
import c13 from '../../img/banner/c_banner13.jpg';
import c14 from '../../img/banner/c_banner14.jpg';
import c15 from '../../img/banner/c_banner15.jpg';
import c16 from '../../img/banner/c_banner16.jpg';
import c17 from '../../img/banner/c_banner17.jpg';
import c18 from '../../img/banner/c_banner18.jpg';
import c19 from '../../img/banner/c_banner19.jpg';
import c20 from '../../img/banner/c_banner20.jpg';
import c21 from '../../img/banner/c_banner21.jpg';
import c22 from '../../img/banner/c_banner22.jpg';
import c23 from '../../img/banner/c_banner23.jpg';
import c24 from '../../img/banner/c_banner24.jpg';
import c25 from '../../img/banner/c_banner25.jpg';
import c26 from '../../img/banner/c_banner26.jpg';
import c27 from '../../img/banner/c_banner27.jpg';
import c28 from '../../img/banner/c_banner28.jpg';
import c29 from '../../img/banner/c_banner29.jpg';
import c30 from '../../img/banner/c_banner30.jpg';
import c31 from '../../img/banner/c_banner31.jpg';
import c32 from '../../img/banner/c_banner32.jpg';
import c33 from '../../img/banner/c_banner33.jpg';
import c34 from '../../img/banner/c_banner34.jpg';
import c35 from '../../img/banner/c_banner35.jpg';
import c36 from '../../img/banner/c_banner36.jpg';
import c37 from '../../img/banner/c_banner37.jpg';
import c38 from '../../img/banner/c_banner38.jpg';
import c39 from '../../img/banner/c_banner39.jpg';
import c40 from '../../img/banner/c_banner40.jpg';
import c41 from '../../img/banner/c_banner41.jpg';
import c42 from '../../img/banner/c_banner42.jpg';
import c43 from '../../img/banner/c_banner43.jpg';
import c44 from '../../img/banner/c_banner44.jpg';
import c45 from '../../img/banner/c_banner45.jpg';
import c46 from '../../img/banner/c_banner46.jpg';
import c47 from '../../img/banner/c_banner47.jpg';

const Partner = () => {
  // 이미지 리스트 데이터
  const privateCompanyImages = [
    <img src={p1}/>,
    <img src={p2}/>,
    <img src={p3}/>,
    <img src={p4}/>,
    <img src={p5}/>,
    <img src={p6}/>,
    <img src={p7}/>,
    <img src={p8}/>,
    <img src={p9}/>,
    <img src={p10}/>,
    <img src={p11}/>,
    <img src={p12}/>
  ];

  const publicCompanyImages = [
    <img src={c1}/>,
    <img src={c2}/>,
    <img src={c3}/>,
    <img src={c4}/>,
    <img src={c5}/>,
    <img src={c6}/>,
    <img src={c7}/>,
    <img src={c8}/>,
    <img src={c9}/>,
    <img src={c10}/>,
    <img src={c11}/>,
    <img src={c12}/>,
    <img src={c13}/>,
    <img src={c14}/>,
    <img src={c15}/>,
    <img src={c16}/>,
    <img src={c17}/>,
    <img src={c18}/>,
    <img src={c19}/>,
    <img src={c20}/>,
    <img src={c21}/>,
    <img src={c22}/>,
    <img src={c23}/>,
    <img src={c24}/>,
    <img src={c25}/>,
    <img src={c26}/>,
    <img src={c27}/>,
    <img src={c28}/>,
    <img src={c29}/>,
    <img src={c30}/>,
    <img src={c31}/>,
    <img src={c32}/>,
    <img src={c33}/>,
    <img src={c34}/>,
    <img src={c35}/>,
    <img src={c36}/>,
    <img src={c37}/>,
    <img src={c38}/>,
    <img src={c39}/>,
    <img src={c40}/>,
    <img src={c41}/>,
    <img src={c42}/>,
    <img src={c43}/>,
    <img src={c44}/>,
    <img src={c45}/>,
    <img src={c46}/>,
    <img src={c47}/>
  ];

  return (
    <div id="contents_in" className="bs_partner">
      <div className="tit_area">
        <h3 className="title">고객/협력사</h3>
      </div>
      
      <div className="content01">
        <div className="container">    
          <div className="title_wg01">
            <div className="tit_w01">CLIENTS & PARTNER</div>
            <div className="tit_w02">다이나시스템과 함께 하고 있는 고객사와 협력사를 소개합니다.</div>
            <div className="line"></div>
          </div>
          <div className="title_wg02">
            <div className="title_w01">
              지속적인 소프트웨어 개발을 통하여 역량 강화를 거듭하며<br />
              <strong>다수의 국내 기관 및 기업들</strong>과 오랜 시간 파트너십을 맺고 있습니다.
            </div>
          </div>
        </div>
      </div>

      <div className="content02">
        <div className="container">
          <div className="gallery_list">
            <div className="g_tit">
              <h2>금융 및 민간기업</h2>
              <p>다이나시스템과 함께 하는 기업</p>
            </div>
            <ul>
              {privateCompanyImages.map((item) => (
                <li key={`private-${item}`} className="content">        
                  <div className="pic">
                    {item}
                  </div>            
                </li>
              ))}
            </ul>
          </div>

          <div className="cont_line01"></div>

          <div className="gallery_list">
            <div className="g_tit">
              <h2>공공기관</h2>
              <p>다이나시스템과 함께 하는 공공기관</p>
            </div>
            <ul>
              {publicCompanyImages.map((item) => (
                <li key={`public-${item}`} className="content">        
                  <div className="pic">
                  {item}
                  </div>            
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default Partner;