import React from "react";

const SubMenu4 = () => {
  return (
    <div id="sub_visual" class="">
      <div class="subtop subtop_recruit"></div>
      <div class="v_txt">
        <h2 data-splitting="">RECRUITMENT</h2>
        <div class="line"></div>
        <p>The Best Leader of Information Technology</p>
      </div>
    </div>
  );
};

export default SubMenu4;
